var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ButtonProgress",
    {
      staticClass: "black--text",
      attrs: {
        small: "",
        "data-cy": "favourite-button",
        block: "",
        color: "#FED401",
        loading: _vm.isLoading,
      },
      on: { click: _vm.toggle },
    },
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }