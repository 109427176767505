var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-stretch justify-space-between",
      attrs: { "data-cy": `mobileStockItem-row-${_vm.index}` },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex", staticStyle: { padding: "5px" } },
        [
          _vm.stockItem.photo
            ? _c("v-img", {
                staticStyle: { "max-width": "20vw", "max-height": "95px" },
                attrs: { contain: "", src: _vm.stockItem.photo },
              })
            : _c("v-img", {
                staticStyle: { "max-width": "20vw", "max-height": "95px" },
                attrs: {
                  contain: "",
                  src: require("../assets/default-product-image.png"),
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column justify-center w-100",
          attrs: { "data-cy": `stock-item-card-${_vm.index}` },
        },
        [
          _c("div", { staticClass: "text-h6" }, [
            _vm._v(" " + _vm._s(_vm.stockItem.description) + " "),
          ]),
          _c("div", [
            _c("div", { staticClass: "font-smaller" }, [
              _vm._v(
                " R " +
                  _vm._s(_vm.formatCurrency(_vm.detail.price)) +
                  " / " +
                  _vm._s(_vm.stockItem.unitOfMeasure) +
                  " "
              ),
            ]),
          ]),
          _vm.detail.qty > 0
            ? _c("div", { staticClass: "font-smaller" }, [
                _vm._v(" " + _vm._s(_vm.detail.qty) + " items in stock "),
              ])
            : _c("div", { staticClass: "font-smaller mt-2" }, [
                _c("span", { staticClass: "red--text" }, [
                  _vm._v("Out of stock"),
                ]),
              ]),
          _vm.detail.qty > 0
            ? _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "w-100 pr-2" },
                  [
                    _c("QuantityButtonSmall", {
                      attrs: { stockItem: _vm.stockItem, height: 30 },
                      on: { add: _vm.add },
                      model: {
                        value: _vm.localAmount,
                        callback: function ($$v) {
                          _vm.localAmount = _vm._n($$v)
                        },
                        expression: "localAmount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-100 pl-2 pr-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "secondary--text",
                        attrs: {
                          "data-cy": "add-to-cart",
                          color: "primary",
                          small: "",
                          disabled: _vm.limit < 1,
                          title: _vm.limit < 1 ? "Purchase limit reached" : "",
                          block: "",
                        },
                        on: { click: _vm.add },
                      },
                      [_vm._v(" Add to Cart ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pr-1" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "success", small: "" },
                        on: { click: _vm.removeFavourite },
                      },
                      [
                        _c("v-img", {
                          attrs: {
                            height: "18",
                            width: "18",
                            contain: "",
                            src: require("../../../../assets/icon-trash-white.svg"),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "w-100 pr-2" },
                    [
                      _c("BuyInStock", {
                        staticClass: "mt-2",
                        attrs: { stockItem: _vm.stockItem, small: true },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        color: "success",
                        small: "",
                        title: "Remove favourite",
                      },
                      on: { click: _vm.removeFavourite },
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "18",
                          width: "18",
                          contain: "",
                          src: require("../../../../assets/icon-trash-white.svg"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "pr-2" },
            [
              _vm.showAlternative
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-2 font-small black--text mr-2",
                      attrs: {
                        block: "",
                        color: "accent",
                        height: "28",
                        to: _vm.categoryLink,
                      },
                    },
                    [_vm._v(" View alternatives ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }