var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-3",
        attrs: {
          "data-cy": "favourites-list",
          headers: _vm.headers,
          id: "data-table",
          items: _vm.items,
          "items-per-page": 15,
          "footer-props": _vm.footerProps,
          "group-by": "detail.category",
          search: _vm.search,
        },
        on: { pagination: _vm.page },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function ({ group, headers, toggle, isOpen }) {
              return [
                _c(
                  "td",
                  { attrs: { colspan: headers.length } },
                  [
                    _c(
                      "v-btn",
                      {
                        ref: group,
                        attrs: { "x-small": "", icon: "" },
                        on: { click: toggle },
                      },
                      [
                        isOpen
                          ? _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiMinus)),
                            ])
                          : _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiPlus)),
                            ]),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "mx-5 font-weight-bold" }, [
                      _vm._v(_vm._s(group)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "top",
            fn: function () {
              return [
                _vm.isLoading
                  ? _c("v-progress-linear", { attrs: { indeterminate: true } })
                  : _vm._e(),
                _c(
                  "v-toolbar",
                  { staticClass: "mb-1" },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce:500",
                          value: _vm.searchChanged,
                          expression: "searchChanged",
                          arg: "500",
                        },
                      ],
                      attrs: {
                        clearable: "",
                        flat: "",
                        "solo-inverted": "",
                        "hide-details": "",
                        "prepend-inner-icon": _vm.mdiMagnify,
                        label: "Search",
                        "debounce-events": "input",
                      },
                    }),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-right",
                        attrs: {
                          title: "Reload favourites",
                          color: "primary",
                          icon: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("refresh")
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(_vm.mdiRefresh)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.photo",
            fn: function ({ item }) {
              return [
                _c("v-img", {
                  attrs: {
                    width: "92",
                    contain: "",
                    src: _vm.resizeImage(item),
                  },
                }),
              ]
            },
          },
          {
            key: "item.detail.price",
            fn: function ({ item }) {
              return [
                _vm._v(
                  " R " + _vm._s(_vm.formatCurrency(item.detail.price)) + " "
                ),
              ]
            },
          },
          {
            key: "item.detail.tax",
            fn: function ({ item }) {
              return [
                item.detail.tax > 0
                  ? _c("span", [
                      _vm._v(
                        "R " + _vm._s(_vm.formatCurrency(item.detail.tax))
                      ),
                    ])
                  : _c("span", [_vm._v("-")]),
              ]
            },
          },
          {
            key: "item.favourites",
            fn: function ({ item }) {
              return [
                _c("favourite", {
                  attrs: {
                    identifier: item.itemCode.toString(),
                    name: `stockItem-${_vm.activeCustomer.customerCode}`,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("favouriteChange")
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c("ButtonAddToCart", {
                  attrs: { stockItem: item },
                  on: { add: _vm.add },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }