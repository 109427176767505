var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "cardWrapper", staticClass: "card-container-wrapper" },
    [
      _vm.showButton && !_vm.hideLeftButton
        ? _c(
            "v-btn",
            {
              staticClass: "less-categories",
              attrs: { icon: "" },
              on: { click: _vm.scrollLeft },
            },
            [_c("img", { attrs: { src: require("../assets/nav-left.png") } })]
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "cardContainer",
          staticClass: "card-container d-flex mb-5 align-stretch",
        },
        _vm._l(_vm.items, function (c, index) {
          return _c(
            "div",
            { key: c.id, staticClass: "shop-card mr-2" },
            [
              c.type === "category"
                ? _c(
                    "v-card",
                    {
                      staticClass: "fill-height category-card",
                      attrs: {
                        elevation: "1",
                        loading: _vm.isLoading,
                        "data-cy": `category-card-${index}`,
                      },
                    },
                    [
                      _c(
                        "v-img",
                        {
                          staticClass: "d-flex align-end",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            "data-cy": `category-link-${index}`,
                            src: c.photoUrl,
                            height: "150px",
                            stretch: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectCategory(_vm.category)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "background-grey-opague mt-1 mb-0 pa-0 pl-2 pr-2",
                              on: {
                                click: function ($event) {
                                  return _vm.selectCategory(_vm.category)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(c.name) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : c.type === "stockItem"
                ? _c(
                    "div",
                    {
                      staticClass: "stock-item-card",
                      attrs: { "data-cy": `stock-item-card-${index}` },
                    },
                    [_c("StockItemCard", { attrs: { stockItem: c } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _vm.showButton
        ? _c(
            "v-btn",
            {
              staticClass: "more-categories",
              attrs: { icon: "" },
              on: { click: _vm.scrollRight },
            },
            [_c("img", { attrs: { src: require("../assets/nav-right.png") } })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }