var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
              _vm._v(" Loading Product "),
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "subheading font-weight-bold" },
                [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
              ),
              _c("v-divider"),
              _c("v-img", {
                attrs: { height: "128", contain: "", src: _vm.item.photo },
              }),
              _c("v-card-text", [_vm._v(" R 0.00 ")]),
              _c(
                "v-card-actions",
                [
                  _c("v-text-field", {
                    attrs: { label: "Quantity", value: 1 },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("toast:notify", "Added to cart")
                        },
                      },
                    },
                    [_vm._v(" Add to Cart ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }