var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    [
      _c("v-select", { attrs: { label: "Select User", items: _vm.users } }),
      _c("v-textarea", {
        attrs: {
          label: "Push Notification Message",
          name: "message",
          maxlength: "256",
        },
      }),
      _c(
        "v-btn",
        { attrs: { color: "primary" }, on: { click: _vm.sendNotification } },
        [
          _c("v-icon", [_vm._v(_vm._s(_vm.mdiBell))]),
          _vm._v(" Send Notification "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }