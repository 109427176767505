var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    ref: "searchBox",
    staticClass: "search-box",
    attrs: {
      "data-cy": "input-search",
      clearable: "",
      label: _vm.label,
      solo: "",
      flat: "",
      "hide-details": "",
    },
    on: { input: _vm.onInput, "click:clear": _vm.clearSearch },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  icon: "",
                                  "data-cy": "toggle-search-type",
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.search?.length > 0
                              ? _c("v-img", {
                                  attrs: {
                                    height: "18",
                                    width: "18",
                                    contain: "",
                                    src: require("../../../../assets/icon-search.svg"),
                                  },
                                })
                              : _vm.searchType === "All"
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiFeatureSearch)),
                                ])
                              : _vm.searchType === "Favourites"
                              ? _c("v-icon", [_vm._v(_vm._s(_vm.mdiHeart))])
                              : _vm.searchType === "Documents"
                              ? _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiTextBoxSearchOutline)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "data-cy": "toggle-search-type-all" } },
                      [
                        _c(
                          "v-list-item-title",
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.searchType = "All"
                                  },
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiFeatureSearch)),
                                ]),
                                _c("strong", [_vm._v("All Products")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { "data-cy": "toggle-search-type-favourites" } },
                      [
                        _c(
                          "v-list-item-title",
                          [
                            _c(
                              "v-btn",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.searchType = "Favourites"
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(_vm.mdiHeart)),
                                ]),
                                _c("strong", [_vm._v("Favourites")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.searchType === "Documents"
                      ? _c(
                          "v-list-item",
                          {
                            attrs: {
                              "data-cy": "toggle-search-type-favourites",
                            },
                          },
                          [
                            _c(
                              "v-list-item-title",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.searchType = "Documents"
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.mdiTextBoxSearchOutline)
                                      ),
                                    ]),
                                    _c("strong", [_vm._v("Accounts")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v
      },
      expression: "search",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }