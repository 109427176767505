var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("table-cart", {
                attrs: { cartItems: _vm.quoteCart, disabled: true },
                scopedSlots: _vm._u([
                  {
                    key: "notes",
                    fn: function () {
                      return [
                        _c("v-textarea", {
                          staticClass: "mt-10",
                          attrs: {
                            disabled: "",
                            outlined: "",
                            label: "Notes",
                            hint: "e.g. special delivery notes",
                            "data-cy": "Quote-notes",
                          },
                          model: {
                            value: _vm.quote.order.notes,
                            callback: function ($$v) {
                              _vm.$set(_vm.quote.order, "notes", $$v)
                            },
                            expression: "quote.order.notes",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "0", color: "#e0e0e0" } },
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" Quote "),
                  ]),
                  _vm.client
                    ? _c("v-card-subtitle", [_c("active-customer")], 1)
                    : _vm._e(),
                  _vm.quote
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "black--text",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm._v(" Order No: "),
                                      _c(
                                        "span",
                                        { staticClass: "float-right" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.quote.order.orderNo)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "black--text",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm._v(" Delivery Date: "),
                                      _c(
                                        "span",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                icon: "",
                                                disabled:
                                                  _vm.quote.order.status ===
                                                  "Integrated",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editDeliveryDate(
                                                    _vm.quote.order
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(_vm._s(_vm.mdiPencil))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.quote.order.deliveryDate
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "black--text",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm._v(" Requested By: "),
                                      _c(
                                        "span",
                                        { staticClass: "float-right" },
                                        [_vm._v(_vm._s(_vm.quote.createdBy))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { staticClass: "mt-5 mb-5" }),
                              _vm.quote.order.notes.length > 0
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "black--text",
                                          attrs: { cols: "12" },
                                        },
                                        [_vm._v(" Notes: ")]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "black--text",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.quote.order.notes) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "black--text",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm.quote.approvedBy
                                        ? _c("div", [
                                            _vm._v(
                                              " This was already " +
                                                _vm._s(
                                                  _vm.getBool(
                                                    _vm.quote.approved
                                                  )
                                                ) +
                                                " by " +
                                                _vm._s(_vm.quote.approvedBy) +
                                                " at " +
                                                _vm._s(
                                                  _vm.formatDateTime(
                                                    _vm.quote.dateApproved
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "error" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.update(false)
                                                    },
                                                  },
                                                },
                                                [_vm._v("Decline")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "float-right",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: _vm.isOnHold,
                                                    "data-cy":
                                                      "Quote-button-checkout",
                                                    type: "submit",
                                                    loading: _vm.isSaving,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.update(true)
                                                    },
                                                  },
                                                },
                                                [_vm._v("Approve")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.quoteCart.length <= 0
                            ? _c(
                                "p",
                                { staticClass: "ma-5" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary--text mr-2 mb-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(" fa-info ")]
                                  ),
                                  _vm._v(" Cart is empty "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.dialogDeliveryDate,
            callback: function ($$v) {
              _vm.dialogDeliveryDate = $$v
            },
            expression: "dialogDeliveryDate",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "text-h5 grey lighten-2 d-flex flex-column align-start",
                },
                [
                  _c("span", [
                    _vm._v(
                      " Editing Order " + _vm._s(_vm.editItem.orderNo) + " "
                    ),
                  ]),
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(
                      " Created by " + _vm._s(_vm.editItem.createdBy) + " "
                    ),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c("DatePicker", {
                    attrs: {
                      label: "Delivery Date",
                      filled: false,
                      clearable: "",
                    },
                    model: {
                      value: _vm.editItem.deliveryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editItem, "deliveryDate", $$v)
                      },
                      expression: "editItem.deliveryDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogDeliveryDate = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", loading: _vm.isSavingItem },
                      on: {
                        click: function ($event) {
                          return _vm.updateDeliveryDate(_vm.editItem)
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }