var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: {
        "data-cy": "favourite-button",
        "x-small": "",
        fab: "",
        color: "success",
        elevation: "0",
        loading: _vm.isLoading,
        rounded: "",
      },
      on: { click: _vm.toggle },
    },
    [
      _c("v-icon", { attrs: { color: "white" } }, [
        _vm._v(" " + _vm._s(_vm.icon) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }