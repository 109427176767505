var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-0 basic-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.quotes,
          loading: _vm.isLoading,
          "sort-by": "order.created",
          "sort-desc": true,
        },
        scopedSlots: _vm._u([
          {
            key: "item.order.created",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.order.created)) + " "),
              ]
            },
          },
          {
            key: "item.dateApproved",
            fn: function ({ item }) {
              return [
                item.dateApproved
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.formatDate(item.dateApproved)) + " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.approved",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatBoolean(item.approved)) + " "),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "v-btn-toggle",
                  [
                    item && _vm.isApprover(item)
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "secondary--text mr-2",
                            attrs: {
                              small: "",
                              color: "#EE1C25",
                              to: {
                                name: "shop-Quote",
                                query: { quoteId: item.id },
                              },
                            },
                          },
                          [_vm._v(" View ")]
                        )
                      : _vm._e(),
                    item && _vm.isApprover(item) && !item.approved
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "secondary--text",
                            attrs: {
                              small: "",
                              color: "primary",
                              to: {
                                name: "shop-EditQuote",
                                query: { quoteId: item.id },
                              },
                            },
                          },
                          [_vm._v(" Edit ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }