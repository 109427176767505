var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c("v-btn", _vm._g(_vm._b({}, "v-btn", attrs, false), on), [
                _vm._v(" Add Sales Order Line "),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Add a Sales Order Line ")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _vm._v("Order Number: " + _vm._s(_vm.salesOrder.id)),
                  ]),
                ],
                1
              ),
              _c(
                "v-form",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              loading: _vm.isLoading,
                              "data-cy": "DialogAddSalesOrderLine-itemCode",
                              label: "Stock Item",
                              "item-text": (i) =>
                                `${i.itemCode} - ${i.description}`,
                              "item-value": "itemCode",
                              items: _vm.stockItems,
                            },
                            model: {
                              value: _vm.salesOrderLine.itemCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesOrderLine, "itemCode", $$v)
                              },
                              expression: "salesOrderLine.itemCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Quantity", required: "" },
                            model: {
                              value: _vm.salesOrderLine.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesOrderLine, "quantity", $$v)
                              },
                              expression: "salesOrderLine.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-space-between" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }