var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          !_vm.localDelivery.id
            ? _c("div", { staticClass: "text-h5" }, [
                _vm._v(" Select a Driver "),
              ])
            : _vm._e(),
          !_vm.localDelivery.id
            ? _c(
                "v-row",
                _vm._l(_vm.drivers, function (item) {
                  return _c(
                    "v-col",
                    { key: item.id, attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mt-5" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-img", {
                                attrs: {
                                  width: "100%",
                                  height: "300",
                                  contain: "",
                                  src: item.licensePhoto,
                                },
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: { innerHTML: _vm._s(item.name) },
                              }),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " PDP Expires: " +
                                    _vm._s(
                                      _vm.formatDate(item.pdpLicenseExpiry)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("v-divider"),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.create(item)
                                    },
                                  },
                                },
                                [_vm._v(" Select ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "GmapMap",
                    {
                      staticStyle: { width: "100%", height: "300px" },
                      attrs: { center: _vm.center, zoom: _vm.zoom },
                    },
                    [
                      _c(
                        "GmapInfoWindow",
                        {
                          attrs: {
                            position: _vm.infoWindowPos,
                            opened: _vm.infoWinOpen,
                          },
                          on: {
                            closeclick: function ($event) {
                              _vm.infoWinOpen = false
                            },
                          },
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.selectedMarker.description),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "d-block text-subtitle-2 text-right",
                              staticStyle: {
                                "font-size": "smaller !important",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.selectedMarker.title) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._l(_vm.markers, function (marker, index) {
                        return _c("GmapMarker", {
                          key: index,
                          attrs: {
                            position: marker.position,
                            title: marker.title,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInfoWindow(marker, index)
                            },
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          filled: "",
                          items: _vm.drivers,
                          "item-text": "name",
                          "item-value": "id",
                          "data-cy": "FormDelivery-driverId",
                          label: _vm.$$t("driverId"),
                        },
                        model: {
                          value: _vm.localDelivery.driverId,
                          callback: function ($$v) {
                            _vm.$set(_vm.localDelivery, "driverId", $$v)
                          },
                          expression: "localDelivery.driverId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "data-cy": "FormDelivery-orderId",
                          filled: "",
                          label: _vm.$$t("orderId"),
                          items: _vm.orders,
                          "item-text": "externalOrderNo",
                          "item-value": "id",
                        },
                        on: { change: _vm.orderChanged },
                        model: {
                          value: _vm.selectedOrder,
                          callback: function ($$v) {
                            _vm.selectedOrder = $$v
                          },
                          expression: "selectedOrder",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _vm._v(" " + _vm._s(_vm.deliveryText) + " "),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.selectedOrder,
                            "data-cy": "FormDelivery-button-add",
                          },
                          on: { click: _vm.addOrder },
                        },
                        [_vm._v(" Add ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormDelivery-status",
                      label: _vm.$$t("status"),
                      items: ["Scheduling", "Delivering", "Completed"],
                    },
                    model: {
                      value: _vm.localDelivery.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.localDelivery, "status", $$v)
                      },
                      expression: "localDelivery.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      filled: "",
                      rows: "3",
                      "auto-grow": "",
                      "data-cy": "FormDelivery-notes",
                      label: _vm.$$t("notes"),
                    },
                    model: {
                      value: _vm.localDelivery.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.localDelivery, "notes", $$v)
                      },
                      expression: "localDelivery.notes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormDelivery-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormDelivery-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }