var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", [
      _c("tr", { attrs: { "data-cy": "quantity-input" } }, [
        _c("td", [
          _c(
            "button",
            {
              staticClass: "button-blocks button-blocks-left",
              attrs: { disabled: _vm.quantity === 0, type: "button" },
              on: {
                click: _vm.decrease,
                touchstart: _vm.longDecrease,
                touchrelease: _vm.releaseDecrease,
                mousedown: _vm.longDecrease,
                mouseup: _vm.releaseDecrease,
              },
            },
            [_c("span", { staticClass: "text-sizing" }, [_vm._v("-")])]
          ),
        ]),
        _c("td", [
          _c("div", { staticClass: "quantity-block" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.localValue,
                  expression: "localValue",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.localValue },
              on: {
                focus: _vm.quantityClicked,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.updateItem(_vm.localValue)
                },
                change: function ($event) {
                  return _vm.updateItem(_vm.localValue)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.localValue = _vm._n($event.target.value)
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
        _c("td", [
          _c(
            "button",
            {
              staticClass: "button-blocks button-blocks-right",
              attrs: { type: "button" },
              on: {
                click: _vm.increase,
                touchstart: _vm.longIncrease,
                touchrelease: _vm.releaseIncrease,
                mousedown: _vm.longIncrease,
                mouseup: _vm.releaseIncrease,
              },
            },
            [_c("span", { staticClass: "text-sizing" }, [_vm._v("+")])]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }