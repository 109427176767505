var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-skeleton-loader",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            transition: "expand-transition",
                            type: "article",
                          },
                        },
                        [
                          _c("FormDivision", {
                            attrs: {
                              Division: _vm.editDivision,
                              isSaving: _vm.isSaving,
                            },
                            on: {
                              save: _vm.save,
                              cancel: function ($event) {
                                return _vm.$router.push({
                                  name: "shop-ListDivision",
                                })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }