var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.emailLogs,
      loading: _vm.isLoading,
      "sort-by": "sent",
      "sort-desc": true,
      search: _vm.search,
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6", offset: "6" } },
                  [
                    _c("v-text-field", {
                      attrs: { "append-icon": _vm.mdiMagnify },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.refresh } },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRefresh))])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.sent",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDateTime(item.sent)) + " ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }