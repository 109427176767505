var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 nav-list",
        attrs: {
          headers: _vm.headers,
          items: _vm.customerLocations,
          loading: _vm.isLoading,
          search: _vm.search,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { class: { "p-16": _vm.$vuetify.breakpoint.mobile } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            class: { "ml-5": !_vm.$vuetify.breakpoint.mobile },
                            attrs: {
                              color: "primary",
                              "data-cy": "CustomerLocation-button-new",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$router.push({
                                  name: "shop-EditCustomerLocation",
                                })
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.$t("common.buttons.new")) + " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                      [
                        _c("v-text-field", {
                          staticClass: "mt-5",
                          attrs: { label: "Search" },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    editLink: {
                      name: "shop-EditCustomerLocation",
                      query: { id: item.id },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }