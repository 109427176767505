var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-list" },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("list-finance-documents", {
            attrs: { arType: "OInv", valueProp: "debit" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }