var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isRestaurantAdmin
    ? _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { scrollable: "", "max-width": "300px" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _vm.isRestaurantAdmin
                          ? _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      text: "",
                                      icon: "",
                                      title: "Change Restaurant",
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiSwapHorizontal)),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                1755662687
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Change to Restaurant: "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-select", {
                        attrs: {
                          loading: _vm.isLoading,
                          items: _vm.items,
                          "return-object": "",
                          "item-text": (c) =>
                            `${c.customerDesc} (${c.customerCode})`,
                          "item-value": "customerCode",
                          label: "Customer",
                        },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.selectedCustomer,
                          callback: function ($$v) {
                            _vm.selectedCustomer = $$v
                          },
                          expression: "selectedCustomer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success", loading: _vm.isSaving },
                          on: { click: _vm.update },
                        },
                        [_vm._v(" Change ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }