var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enabled
    ? _c(
        "div",
        { attrs: { "data-cy": "AuroraServer-Maintenance" } },
        [
          _c(
            "v-alert",
            {
              attrs: {
                type: "warning",
                "data-cy": "AuroraServer-Maintenance-Alert",
                dismissible: "",
                rounded: "0",
              },
              model: {
                value: _vm.showWarning,
                callback: function ($$v) {
                  _vm.showWarning = $$v
                },
                expression: "showWarning",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("common.maintenance.warning")) +
                  " " +
                  _vm._s(_vm.$t("common.maintenance.startsIn")) +
                  " " +
                  _vm._s(_vm.timeRemaining) +
                  ": " +
                  _vm._s(_vm.latestMaintenance.message) +
                  " "
              ),
            ]
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                overlay: true,
                "max-width": "500px",
                transition: "dialog-transition",
              },
              model: {
                value: _vm.showMaintenance,
                callback: function ($$v) {
                  _vm.showMaintenance = $$v
                },
                expression: "showMaintenance",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { "data-cy": "AuroraServer-Maintenance-Dialog" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "warning", dark: "" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { large: "" } },
                        [_vm._v(_vm._s(_vm.mdiAlertOutline))]
                      ),
                      _c("div", { staticClass: "text-h5" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.maintenance.title")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _c("div", { staticClass: "text-h6 mt-5" }, [
                      _vm._v(" " + _vm._s(_vm.latestMaintenance.message) + " "),
                    ]),
                    _c("div", { staticClass: "text-h6 mt-5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.maintenance.completesIn")) +
                          " " +
                          _vm._s(_vm.timeRemaining) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }