var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading ? _c("StockItemsLoading") : _vm._e(),
      _vm.displayCart.length === 0
        ? _c(
            "v-row",
            { staticClass: "d-flex align-center justify-center" },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/icon-empty-cart.svg"),
                      alt: "Empty cart",
                    },
                  }),
                  _c("div", { staticClass: "text-h5 font-weight-bold" }, [
                    _vm._v(" Your cart is empty "),
                  ]),
                  _c("div", { staticClass: "text-subtitle" }, [
                    _vm._v(
                      " Looks like you haven't added anything to your cart yet. "
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text mt-5",
                      attrs: { color: "accent", elevation: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "shop-ShopCategories",
                          })
                        },
                      },
                    },
                    [_vm._v(" Continue shopping ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "8" } },
                [
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c("TableCart", { attrs: { cartItems: _vm.displayCart } })
                    : _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("div", {
                              staticStyle: {
                                "border-bottom": "1px solid grey",
                              },
                            }),
                          ]),
                          _vm._l(_vm.displayCart, function (item) {
                            return _c(
                              "v-col",
                              { key: item.name, attrs: { cols: "12" } },
                              [
                                _c("MobileStockItem", {
                                  attrs: {
                                    isCheckout: true,
                                    stockItem: item.stockItem,
                                    showTotal: true,
                                    showAlternatives: false,
                                    showRemove: true,
                                    amount: parseFloat(item.amount, 10),
                                  },
                                  on: {
                                    remove: function ($event) {
                                      return _vm.removeItem(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      class: { "pa-5": !_vm.$vuetify.breakpoint.mobile },
                      attrs: {
                        color: _vm.$vuetify.breakpoint.mobile ? "" : "accent",
                        flat: "",
                      },
                    },
                    [
                      !_vm.$vuetify.breakpoint.mobile
                        ? _c(
                            "v-card-title",
                            {
                              staticClass: "text-h5",
                              attrs: { "primary-title": "" },
                            },
                            [_vm._v(" Checkout ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-text",
                        { class: { "pa-0": _vm.$vuetify.breakpoint.mobile } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          solo: _vm.isDesktop,
                                          outlined: _vm.isMobile,
                                          label: "Order No",
                                          "data-cy": "Cart-orderNo",
                                          rules: [_vm.rules.required],
                                          "background-color": "white",
                                        },
                                        model: {
                                          value: _vm.orderNo,
                                          callback: function ($$v) {
                                            _vm.orderNo = $$v
                                          },
                                          expression: "orderNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          label: "Delivery Date",
                                          "data-cy": "Cart-deliveryDate",
                                          filled: false,
                                          rules: [_vm.rules.required],
                                          "allowed-dates": _vm.allowedDates,
                                          disableEdit: true,
                                          oneWeekRule: !_vm.isAdmin,
                                          solo: _vm.isDesktop,
                                          outlined: _vm.isMobile,
                                        },
                                        model: {
                                          value: _vm.deliveryDate,
                                          callback: function ($$v) {
                                            _vm.deliveryDate = $$v
                                          },
                                          expression: "deliveryDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.activeCustomer
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.activeCustomer.multipleDeliveries
                                            ? _c("DeliveryAddresses", {
                                                attrs: {
                                                  "background-color": "white",
                                                  rules: [_vm.rules.required],
                                                  "data-cy":
                                                    "Cart-DeliveryAddresses",
                                                  solo: _vm.isDesktop,
                                                  outlined: _vm.isMobile,
                                                },
                                                on: {
                                                  input:
                                                    _vm.deliveryAddressChanged,
                                                },
                                              })
                                            : _c("DeliveryAddress", {
                                                attrs: {
                                                  solo: _vm.isDesktop,
                                                  outlined: _vm.isMobile,
                                                  "data-cy":
                                                    "Cart-DeliveryAddress",
                                                },
                                              }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          solo: _vm.isDesktop,
                                          outlined: _vm.isMobile,
                                          label: "Notes",
                                          hint: "e.g. special delivery notes",
                                          "data-cy": "Cart-notes",
                                          rules: [],
                                          counter: "true",
                                          length: "2000",
                                          "background-color": "white",
                                        },
                                        model: {
                                          value: _vm.notes,
                                          callback: function ($$v) {
                                            _vm.notes = $$v
                                          },
                                          expression: "notes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          solo: _vm.isDesktop,
                                          outlined: _vm.isMobile,
                                          items: _vm.activeCustomer.costCentres,
                                          clearable: "",
                                          "background-color": "white",
                                          "item-text": "name",
                                          "return-object": "",
                                          label: "Cost Centre",
                                          "data-cy": "Cart-costCentre",
                                          "max-width": "300",
                                        },
                                        model: {
                                          value: _vm.costCentre,
                                          callback: function ($$v) {
                                            _vm.costCentre = $$v
                                          },
                                          expression: "costCentre",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-h6 font-weight-bold black--text",
                                          },
                                          [_vm._v(" Total ")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-h6 font-weight-bold black--text",
                                          },
                                          [
                                            _vm._v(
                                              " R " +
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    _vm.total_amount
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.isApproval
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                disabled: _vm.isOnHold,
                                                type: "submit",
                                                "data-cy": "Cart-button-quote",
                                                block: "",
                                                height: "48",
                                                color: "primary",
                                                loading: _vm.isSaving,
                                              },
                                            },
                                            [_vm._v(" Quote ")]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                disabled: _vm.isOnHold,
                                                block: "",
                                                height: "48",
                                                "data-cy":
                                                  "Cart-button-checkout",
                                                color: "primary",
                                                type: "submit",
                                                loading: _vm.isSaving,
                                              },
                                            },
                                            [_vm._v(" Checkout ")]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }