var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("v-calendar", { attrs: { events: _vm.dates, color: "primary" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }