var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-container d-flex mb-5",
      staticStyle: { "overflow-x": "scroll" },
    },
    _vm._l(_vm.products, function (c, index) {
      return _c(
        "v-card",
        {
          key: c.id,
          staticClass: "shop-card mr-2 fill-height",
          attrs: { rounded: "0", elevation: "1" },
        },
        [
          _c(
            "v-img",
            {
              staticClass: "d-flex align-end",
              staticStyle: { cursor: "pointer" },
              attrs: {
                "data-cy": `category-link-${index}`,
                src: c.photoUrl,
                height: "150px",
                stretch: "",
              },
              on: {
                click: function ($event) {
                  return _vm.selectCategory(_vm.category)
                },
              },
            },
            [
              _c(
                "v-card-title",
                {
                  on: {
                    click: function ($event) {
                      return _vm.selectCategory(_vm.category)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(c.name) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }