var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stockItem
    ? _c(
        "v-card",
        {
          staticClass: "d-flex flex-column w-100 h-100",
          attrs: { "data-cy": "stock-item", elevation: "2" },
        },
        [
          _c(
            "div",
            { staticClass: "stock-item-favourite" },
            [
              _vm.activeCustomer
                ? _c("FavouriteIcon", {
                    attrs: {
                      identifier: _vm.stockItem?.itemCode?.toString(),
                      name: `stockItem-${_vm.activeCustomer.customerCode}`,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("favouriteChange")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "stock-item-photo" },
            [
              _vm.stockItem?.photo
                ? _c("v-img", {
                    attrs: {
                      contain: "",
                      src: _vm.detail.img,
                      width: 200,
                      height: 200,
                    },
                    on: { click: _vm.editPhoto },
                  })
                : _c("v-img", {
                    attrs: {
                      contain: "",
                      src: require("../assets/default-product-image.png"),
                      width: 200,
                      height: 200,
                    },
                    on: { click: _vm.editPhoto },
                  }),
            ],
            1
          ),
          _c("v-card-title", { staticClass: "shop-title text-center pa-0" }, [
            _c("span", { staticClass: "w-100" }, [
              _vm._v(_vm._s(_vm.stockItem?.description)),
            ]),
          ]),
          _c(
            "v-card-text",
            { staticClass: "text-center flex-grow-1 d-flex flex-column" },
            [
              _c(
                "v-row",
                { staticClass: "flex-grow-1" },
                [
                  _c("v-col", { staticClass: "pa-2", attrs: { cols: "12" } }, [
                    _vm._v(
                      " R " + _vm._s(_vm.formatCurrency(_vm.detail.price)) + " "
                    ),
                    _vm.detail.tax > 0
                      ? _c("span", [
                          _vm._v(
                            "(R " +
                              _vm._s(_vm.formatCurrency(_vm.detail.tax)) +
                              " VAT)"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" / " + _vm._s(_vm.stockItem?.unitOfMeasure) + " "),
                  ]),
                  !_vm.activeCustomer.hideStockOnHand
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "12" } },
                        [
                          _vm.detail.qty > 0
                            ? _c(
                                "span",
                                { staticClass: "d-block text-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.detail.qty) +
                                      " items in stock"
                                  ),
                                ]
                              )
                            : _c("span", { staticClass: "d-block red--text" }, [
                                _vm._v("Out of stock"),
                              ]),
                          _vm.detail.limit
                            ? _c("span", { staticClass: "d-block" }, [
                                _vm._v(
                                  " Limit " +
                                    _vm._s(_vm.detail.limit.limit) +
                                    " per customer (" +
                                    _vm._s(_vm.detail.limit.remaining) +
                                    " left) "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "flex-grow-0" },
                [
                  _vm.detail.qty === 0
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "12" } },
                        [
                          _c("BuyInStock", {
                            attrs: { stockItem: _vm.stockItem },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.qty > 0
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "6" } },
                        [
                          _c("QuantityButtonBasic", {
                            ref: "quantity",
                            attrs: { limit: _vm.limit },
                            on: { add: _vm.add },
                            model: {
                              value: _vm.amount,
                              callback: function ($$v) {
                                _vm.amount = $$v
                              },
                              expression: "amount",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detail.qty > 0
                    ? _c(
                        "v-col",
                        { staticClass: "pa-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary--text w-100",
                              staticStyle: { "font-size": "1em !important" },
                              attrs: {
                                "data-cy": "add-to-cart",
                                height: "40",
                                block: "",
                                color: "primary",
                                disabled: _vm.limit < 1,
                                title:
                                  _vm.limit < 1 ? "Purchase limit reached" : "",
                              },
                              on: { click: _vm.add },
                            },
                            [_vm._v(" Add to Cart ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "pa-1", attrs: { cols: "12" } },
                    [
                      _vm.showAlternative
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mb-2 black--text",
                              staticStyle: {
                                "align-self": "end",
                                "font-size": "1em !important",
                              },
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                                height: "40",
                                to: _vm.categoryLink,
                              },
                            },
                            [_vm._v(" View alternatives ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "350px", transition: "dialog-transition" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Edit Photo "),
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "text-center d-flex justify-center" },
                    [
                      _vm.stockItem?.photo
                        ? _c(
                            "v-img",
                            {
                              attrs: {
                                contain: "",
                                src: _vm.detail.img,
                                width: 200,
                                height: 200,
                              },
                              on: { click: _vm.editPhoto },
                            },
                            [
                              _c("ImageUpload", {
                                on: {
                                  input: function ($event) {
                                    return _vm.updateImage(
                                      _vm.stockItem,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-img",
                            {
                              attrs: {
                                contain: "",
                                src: require("../assets/default-product-image.png"),
                                width: 200,
                                height: 200,
                              },
                              on: { click: _vm.editPhoto },
                            },
                            [
                              _c("ImageUpload", {
                                on: {
                                  input: function ($event) {
                                    return _vm.updateImage(
                                      _vm.stockItem,
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _vm.stockItem?.photo
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: { click: _vm.removeImage },
                            },
                            [_vm._v(" Delete Photo ")]
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Done ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }