var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c("v-text-field", {
            attrs: { "data-cy": "FormCostCentre-name", label: _vm.$$t("name") },
            model: {
              value: _vm.localCostCentre.name,
              callback: function ($$v) {
                _vm.$set(_vm.localCostCentre, "name", $$v)
              },
              expression: "localCostCentre.name",
            },
          }),
          _vm._v(" Approvers "),
          _c("select-users", {
            attrs: {
              "data-cy": "FormCostCentre-approvers",
              label: "Approvers",
            },
            model: {
              value: _vm.localCostCentre.approvers,
              callback: function ($$v) {
                _vm.$set(_vm.localCostCentre, "approvers", $$v)
              },
              expression: "localCostCentre.approvers",
            },
          }),
          _vm._v(" Buyers "),
          _c("select-users", {
            attrs: { "data-cy": "FormCostCentre-buyers", label: "Buyers" },
            model: {
              value: _vm.localCostCentre.buyers,
              callback: function ($$v) {
                _vm.$set(_vm.localCostCentre, "buyers", $$v)
              },
              expression: "localCostCentre.buyers",
            },
          }),
          _c("v-checkbox", {
            attrs: {
              filled: "",
              label: "Requires Approval",
              "data-cy": "FormCostCentre-requiresApproval",
            },
            model: {
              value: _vm.localCostCentre.requiresApproval,
              callback: function ($$v) {
                _vm.$set(_vm.localCostCentre, "requiresApproval", $$v)
              },
              expression: "localCostCentre.requiresApproval",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormCostCentre-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormCostCentre-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }