var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.divisions.length > 0
    ? _c("v-select", {
        attrs: {
          clearable: "",
          flat: "",
          "hide-details": "",
          "item-value": "id",
          "item-text": "name",
          items: _vm.divisions,
          label: "Departments",
        },
        model: {
          value: _vm.divisionId,
          callback: function ($$v) {
            _vm.divisionId = $$v
          },
          expression: "divisionId",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }