var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                  on
                ),
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiPencilBoxOutline))])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.update.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _vm._v(" Item Code: " + _vm._s(_vm.stockItem.itemCode) + " "),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Product Description" },
                    model: {
                      value: _vm.localValue.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.localValue, "name", $$v)
                      },
                      expression: "localValue.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.cancel } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("common.buttons.cancel")) + " "
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c("v-btn", { attrs: { color: "success", type: "submit" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }