<template>
<div>
  <div ref="printArea">
    <div>
      <h3>Company Delivery Details:</h3>
      <p>{{ salesOrder.customerName }}</p>
      <p>{{ salesOrder.deliveryAddress1 }}</p>
      <p>{{ salesOrder.deliveryAddress2 }}</p>
      <p>{{ salesOrder.deliveryAddress3 }},</p>
      <p>{{ salesOrder.deliveryAddress4 }}</p>
    </div>

    <div>
      <p>Dispatch Date: {{ salesOrder.deliveryDate }}</p>
      <p>Printed Date: {{ new Date() }}</p>
      <p>Page: 1</p>
    </div>

    <table border="1" cellspacing="0" cellpadding="5">
      <tr>
        <th>Code</th>
        <th>Description</th>
        <th>Qty</th>
        <th>Unit</th>
        <th>Bin</th>
        <th>Quantity (to be picked)</th>
      </tr>
      <tr v-for="item in lines" :key="item.code">
        <td>{{ item.code }}</td>
        <td>{{ item.description }}</td>
        <td>{{ item.quantity }}</td>
        <td>{{ item.unitOfMeasure }}</td>
        <td>{{ item.bin }}</td>
        <td>________________________</td>
      </tr>
    </table>

    <div>
      <p>Picker: ___________________________________________</p>
      <p>Date: _____________________________________________</p>
      <p>Signature: ________________________________________</p>
    </div>
  </div>
  <button @click="print">Print</button>
</div>
</template>

<script>
export default {
  name: 'PickingSlip',
  props: {
    salesOrder: {
      type: Object,
      required: true,
    },
    lines: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      printedDate: new Date().toLocaleDateString(),
    };
  },
  methods: {
    print() {
      this.$print(this.$refs.printArea);
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
}
th, td {
  padding: 5px;
  text-align: left;
}
</style>
