var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "6", sm: "6", xs: "6" } }, [
            _c("div", { staticClass: "headline" }, [
              _c("b", [_vm._v("Inspection Date:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.formatDate(_vm.localVehicleInspection.created)) +
                  " "
              ),
            ]),
          ]),
          _c(
            "v-col",
            {
              staticClass: "text-right",
              attrs: { cols: "12", md: "6", sm: "6", xs: "6" },
            },
            [
              _c("div", { staticClass: "headline" }, [
                _c("b", [_vm._v("Inspection Done by:")]),
                _vm._v(" " + _vm._s(_vm.username) + " "),
              ]),
            ]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", sm: "4", xs: "4" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.vehicles,
                  "item-text": (v) => `${v.make} ${v.model} ${v.licensePlate}`,
                  "item-value": "id",
                  "data-cy": "FormVehicleInspection-vehicleId",
                  label: _vm.$$t("vehicleId"),
                },
                model: {
                  value: _vm.localVehicleInspection.vehicleId,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "vehicleId", $$v)
                  },
                  expression: "localVehicleInspection.vehicleId",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", sm: "4", xs: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicleInspection-odometerReading",
                  label: _vm.$$t("odometerReading"),
                },
                model: {
                  value: _vm.localVehicleInspection.odometerReading,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "odometerReading", $$v)
                  },
                  expression: "localVehicleInspection.odometerReading",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", sm: "4", xs: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicleInspection-fuelLevel",
                  label: _vm.$$t("fuelLevel"),
                },
                model: {
                  value: _vm.localVehicleInspection.fuelLevel,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "fuelLevel", $$v)
                  },
                  expression: "localVehicleInspection.fuelLevel",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-engineOil",
                  filled: "",
                  label: _vm.$$t("engineOil"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.engineOil,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "engineOil", $$v)
                  },
                  expression: "localVehicleInspection.engineOil",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-coolant",
                  filled: "",
                  label: _vm.$$t("coolant"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.coolant,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "coolant", $$v)
                  },
                  expression: "localVehicleInspection.coolant",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-brakeFluid",
                  filled: "",
                  label: _vm.$$t("brakeFluid"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.brakeFluid,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "brakeFluid", $$v)
                  },
                  expression: "localVehicleInspection.brakeFluid",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-tireCondition",
                  filled: "",
                  label: _vm.$$t("tireCondition"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.tireCondition,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "tireCondition", $$v)
                  },
                  expression: "localVehicleInspection.tireCondition",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-brakeCondition",
                  filled: "",
                  label: _vm.$$t("brakeCondition"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.brakeCondition,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "brakeCondition", $$v)
                  },
                  expression: "localVehicleInspection.brakeCondition",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-headlights",
                  filled: "",
                  label: _vm.$$t("headlights"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.headlights,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "headlights", $$v)
                  },
                  expression: "localVehicleInspection.headlights",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-taillights",
                  filled: "",
                  label: _vm.$$t("taillights"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.taillights,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "taillights", $$v)
                  },
                  expression: "localVehicleInspection.taillights",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-turnSignals",
                  filled: "",
                  label: _vm.$$t("turnSignals"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.turnSignals,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "turnSignals", $$v)
                  },
                  expression: "localVehicleInspection.turnSignals",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-brakeLights",
                  filled: "",
                  label: _vm.$$t("brakeLights"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.brakeLights,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "brakeLights", $$v)
                  },
                  expression: "localVehicleInspection.brakeLights",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-horn",
                  filled: "",
                  label: _vm.$$t("horn"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.horn,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "horn", $$v)
                  },
                  expression: "localVehicleInspection.horn",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-windshieldCondition",
                  filled: "",
                  label: _vm.$$t("windshieldCondition"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.windshieldCondition,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localVehicleInspection,
                      "windshieldCondition",
                      $$v
                    )
                  },
                  expression: "localVehicleInspection.windshieldCondition",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-wipers",
                  filled: "",
                  label: _vm.$$t("wipers"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.wipers,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "wipers", $$v)
                  },
                  expression: "localVehicleInspection.wipers",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-mirrors",
                  filled: "",
                  label: _vm.$$t("mirrors"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.mirrors,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "mirrors", $$v)
                  },
                  expression: "localVehicleInspection.mirrors",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-seatbelts",
                  filled: "",
                  label: _vm.$$t("seatbelts"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.seatbelts,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "seatbelts", $$v)
                  },
                  expression: "localVehicleInspection.seatbelts",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-interiorCondition",
                  filled: "",
                  label: _vm.$$t("interiorCondition"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.interiorCondition,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localVehicleInspection,
                      "interiorCondition",
                      $$v
                    )
                  },
                  expression: "localVehicleInspection.interiorCondition",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-exteriorCondition",
                  filled: "",
                  label: _vm.$$t("exteriorCondition"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.exteriorCondition,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localVehicleInspection,
                      "exteriorCondition",
                      $$v
                    )
                  },
                  expression: "localVehicleInspection.exteriorCondition",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-cabin",
                  filled: "",
                  label: _vm.$$t("cabin"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.cabin,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "cabin", $$v)
                  },
                  expression: "localVehicleInspection.cabin",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-fireExtinguisher",
                  filled: "",
                  label: _vm.$$t("fireExtinguisher"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.fireExtinguisher,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localVehicleInspection,
                      "fireExtinguisher",
                      $$v
                    )
                  },
                  expression: "localVehicleInspection.fireExtinguisher",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-fridge",
                  filled: "",
                  label: _vm.$$t("fridge"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.fridge,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "fridge", $$v)
                  },
                  expression: "localVehicleInspection.fridge",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-clean",
                  filled: "",
                  label: _vm.$$t("clean"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.clean,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "clean", $$v)
                  },
                  expression: "localVehicleInspection.clean",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3", sm: "3", xs: "3" } },
            [
              _c("v-select", {
                attrs: {
                  "data-cy": "FormVehicleInspection-cratesReturned",
                  filled: "",
                  label: _vm.$$t("cratesReturned"),
                  items: _vm.items,
                },
                model: {
                  value: _vm.localVehicleInspection.cratesReturned,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "cratesReturned", $$v)
                  },
                  expression: "localVehicleInspection.cratesReturned",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "text-h6" }, [_vm._v("Photos")]),
              _c("MultipleImageField", {
                attrs: {
                  rows: "4",
                  filled: "",
                  "data-cy": "FormVehicleInspection-photos",
                  label: _vm.$$t("photos"),
                },
                model: {
                  value: _vm.localVehicleInspection.photos,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "photos", $$v)
                  },
                  expression: "localVehicleInspection.photos",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: {
                  rows: "4",
                  filled: "",
                  "data-cy": "FormVehicleInspection-comments",
                  label: _vm.$$t("comments"),
                  counter: "2000",
                },
                model: {
                  value: _vm.localVehicleInspection.comments,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicleInspection, "comments", $$v)
                  },
                  expression: "localVehicleInspection.comments",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4 btn-cancel",
              attrs: {
                color: "default",
                outlined: "",
                "data-cy": "FormVehicleInspection-button-cancel",
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "primary",
                type: "submit",
                loading: _vm.isSaving,
                "data-cy": "FormVehicleInspection-button-save",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }