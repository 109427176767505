var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-cy": "FileUpload-input" } },
    [
      _c("v-file-input", {
        attrs: {
          "hide-input": "",
          loading: _vm.isLoading,
          accept: _vm.accept,
          "prepend-icon": _vm.icon,
          "error-messages": _vm.errors,
        },
        on: { change: _vm.fileChanged },
        model: {
          value: _vm.file,
          callback: function ($$v) {
            _vm.file = $$v
          },
          expression: "file",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }