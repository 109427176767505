var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c("v-data-iterator", {
        ref: "dataTable",
        attrs: {
          id: "data-operator",
          "data-cy": "favourites-list",
          items: _vm.stockItems,
          "items-per-page": 12,
          "footer-props": _vm.footerProps,
          "hide-default-footer": _vm.stockItems.length < 12,
          loading: _vm.loading,
          "no-results-text": _vm.noResultsText,
          "no-data-text": _vm.noDataText,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$vuetify.goTo(_vm.$refs.dataTable, _vm.goToOptions)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ items }) {
              return [
                _c(
                  "v-row",
                  { staticClass: "d-flex" },
                  _vm._l(items, function (stockItem, index) {
                    return _c(
                      "v-col",
                      {
                        key: `stockItem-${index}`,
                        staticClass:
                          "d-flex align-stretch col-sm-padding stock-item-card",
                        attrs: {
                          cols: "12",
                          xs: "12",
                          sm: "6",
                          md: "4",
                          lg: "3",
                          xl: "3",
                          "data-cy": `stock-item-card-${index}`,
                        },
                      },
                      [
                        _c("StockItemCard", {
                          attrs: { stockItem: stockItem },
                          on: {
                            add: _vm.add,
                            change: function ($event) {
                              return _vm.$emit("change", $event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }