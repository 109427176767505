var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        "align-start": "",
        "justify-center": "",
        column: "",
        "fill-height": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("div", { staticClass: "text-h5 bold fade-in" }, [
                      _vm._v(
                        "Welcome, " + _vm._s(_vm.displayNameOrUsername) + "!"
                      ),
                    ]),
                    _c("div", { staticClass: "text-h6 fade-in" }, [
                      _vm._v("Where would you like to go?"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "w-100", attrs: { grow: "" } },
        [
          _c(
            "v-container",
            { staticClass: "blocks-container w-100" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.menuItems, function (item) {
                  return _c(
                    "v-col",
                    {
                      key: item.name,
                      staticClass: "home-menu-item flex-grow-1",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "d-flex fill-height",
                          attrs: { to: { name: item.to } },
                        },
                        [
                          _c("img", {
                            staticClass: "fill-height w-100",
                            class: { "h-100": _vm.isMobile },
                            attrs: { src: item.imageUrl },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }