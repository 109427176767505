var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [_c("PhotoUpload", { on: { input: _vm.addImage } })],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap ml-5 w-100" },
        _vm._l(_vm.images, function (v, index) {
          return _c("v-img", {
            key: index,
            staticClass: "mr-2",
            attrs: { src: v, alt: _vm.label, width: "250", contain: "" },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }