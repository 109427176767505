var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOnHold
    ? _c(
        "v-alert",
        {
          attrs: {
            dense: "",
            border: "left",
            type: "warning",
            icon: _vm.mdiAlertOutline,
          },
        },
        [
          _vm._v(
            " Your account is currently on hold, please contact Gourmet Foods to have it enabled again. "
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }