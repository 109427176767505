var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-col",
        {
          key: item.name,
          attrs: { cols: "6", xs: "6", sm: "6", md: "6", lg: "6", xl: "6" },
        },
        [
          _c(
            "v-card",
            { attrs: { to: { name: item.to } } },
            [
              _c("v-img", {
                attrs: { src: item.photo, height: _vm.height, width: "100%" },
              }),
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }