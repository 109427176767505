var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm.isLoading
                ? _c("ProgressLinearTimeout")
                : _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item-group",
                        {
                          model: {
                            value: _vm.selectedItem,
                            callback: function ($$v) {
                              _vm.selectedItem = $$v
                            },
                            expression: "selectedItem",
                          },
                        },
                        _vm._l(_vm.items, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              attrs: { loading: item.isDownloading },
                              on: {
                                click: function ($event) {
                                  return _vm.download(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.reference)),
                                  ]),
                                  _c(
                                    "v-list-item-subtitle",
                                    { staticStyle: { "text-wrap": "normal" } },
                                    [
                                      _vm._v(
                                        " Amount: R " +
                                          _vm._s(
                                            _vm.formatCurrency(item.amount)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-list-item-subtitle",
                                    { staticStyle: { "text-wrap": "normal" } },
                                    [
                                      _vm._v(
                                        " Outstanding: R " +
                                          _vm._s(
                                            _vm.formatCurrency(item.outstanding)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatDate(item.txDate)) +
                                      " "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary--text",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary",
                                        loading: item.isEmailing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.email(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" Email ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  loading: _vm.isLoading,
                  search: _vm.search,
                  "item-key": "reference",
                  "disable-sort": _vm.$vuetify.breakpoint.mobile,
                  "sort-by": "txDate",
                  "sort-desc": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.txDate",
                    fn: function ({ item }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.formatDate(item.txDate)) + " "),
                      ]
                    },
                  },
                  {
                    key: "item.amount",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "text-right" }, [
                          _vm._v(
                            " R " +
                              _vm._s(_vm.formatCurrency(item.amount)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.outstanding",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "text-right" }, [
                          _vm._v(
                            " R " +
                              _vm._s(_vm.formatCurrency(item.outstanding)) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn-toggle",
                          [
                            !_vm.$vuetify.breakpoint.mobile
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "secondary--text mr-2",
                                    attrs: {
                                      small: "",
                                      loading: item.isDownloading,
                                      color: "#EE1C25",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.download(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" View ")]
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary--text",
                                attrs: {
                                  small: "",
                                  color: "primary",
                                  loading: item.isEmailing,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.email(item)
                                  },
                                },
                              },
                              [_vm._v(" Email ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }