var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-text", { staticClass: "text-no-wrap text--primary mt-3" }, [
        _c("p", { staticClass: "font-weight-semibold text-h5 mb-1" }, [
          _vm._v(" " + _vm._s(_vm.statTitle) + " "),
        ]),
        _c(
          "p",
          {
            staticClass: "text-h4 primary--text mb-0",
            staticStyle: { cursor: "pointer" },
            on: {
              click: function ($event) {
                return _vm.$emit("click", {
                  statTitle: _vm.statTitle,
                  subtitle: _vm.subtitle,
                  footNote: _vm.footNote,
                })
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
        ),
        _c("p", { staticClass: "text-h6 grey--text mb-0" }, [
          _vm._v(" " + _vm._s(_vm.total) + " "),
        ]),
        _c("p", { staticClass: "text-caption grey--text mb-0" }, [
          _vm._v(" " + _vm._s(_vm.footNote) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }