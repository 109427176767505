import { render, staticRenderFns } from "./FormRestaurantAdmin.vue?vue&type=template&id=56d87188"
import script from "./FormRestaurantAdmin.vue?vue&type=script&lang=js"
export * from "./FormRestaurantAdmin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FormRestaurantAdmin.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d87188')) {
      api.createRecord('56d87188', component.options)
    } else {
      api.reload('56d87188', component.options)
    }
    module.hot.accept("./FormRestaurantAdmin.vue?vue&type=template&id=56d87188", function () {
      api.rerender('56d87188', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/shop/src/components/FormRestaurantAdmin.vue"
export default component.exports