import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('salesOrderLine', 'salesOrderLine');

const customActions = {
  async LOAD_salesOrderLinesBySalesOrder({ commit, rootGetters }, salesOrderId) {
    const data = await odata.o(rootGetters)
      .get('salesOrderLines')
      .query({
        $filter: `IsDeleted eq false AND SalesOrderId eq ${salesOrderId}`,
        $orderby: 'LineNumber',
      });
    commit('SET_salesOrderLines', data);
    return data;
  },
};

export default { ...actions, ...customActions };
