var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-cy": "FileUpload-input" } },
    [
      _c(
        "v-file-input",
        {
          attrs: {
            label: _vm.label,
            loading: _vm.isLoading,
            accept: _vm.accept,
            "show-size": "",
            "prepend-icon": _vm.icon,
            "error-messages": _vm.errors,
          },
          on: { change: _vm.fileChanged },
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v
            },
            expression: "file",
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "append",
                color: "success",
                loading: _vm.isLoading,
                "data-cy": "FileUpload-buttonUpload",
                small: "",
                disabled: !_vm.file,
              },
              on: { click: _vm.upload },
              slot: "append",
            },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v(" mdi-arrow-up-bold-circle-outline "),
              ]),
              _vm._v(" Upload "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }