var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.categories,
      loading: _vm.isLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "item.photo",
        fn: function ({ item }) {
          return [
            _c("v-img", {
              attrs: { src: item.photo, contain: "", height: "128" },
            }),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c("TableBasicButtons", {
              attrs: {
                editLink: {
                  name: "shop-EditCategory",
                  query: { id: item.id },
                },
              },
              on: {
                delete: function ($event) {
                  return _vm.remove(item)
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }