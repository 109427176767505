var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { class: { ios: _vm.isIos } },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "fill-height d-flex flex-column align-stretch",
                      attrs: { cols: "12", md: "4" },
                    },
                    [
                      _c("v-img", {
                        staticClass: "slide-from-top mb-5",
                        staticStyle: {
                          "max-height": "100px",
                          "max-width": "100%",
                        },
                        attrs: {
                          src: require("../assets/Logo-Wide.svg"),
                          contain: "",
                          alt: "logo",
                        },
                      }),
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [_c("router-view")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }