var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { small: "", color: "primary" },
                        on: { click: _vm.fetchLogs },
                      },
                      on
                    ),
                    [_vm._v("View Logs")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("Order Logs")]),
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.logs, function (log) {
                  return _c("div", { key: log.id }, [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v(_vm._s(log.created)),
                    ]),
                    _c("div", { staticClass: "text-h5" }, [_vm._v("JSON:")]),
                    _c("pre", [
                      _vm._v("          " + _vm._s(log.json) + "\n        "),
                    ]),
                    _c("div", { staticClass: "text-h5" }, [
                      _vm._v("Response:"),
                    ]),
                    _c("pre", [
                      _vm._v(
                        "          " + _vm._s(log.response) + "\n        "
                      ),
                    ]),
                  ])
                }),
                0
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }