var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      class: _vm.$vuetify.breakpoint.mobile ? "" : "pa-6",
      attrs: { fluid: "" },
    },
    [
      _vm.resultType === "table"
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      "item-key": "itemCode",
                      loading: _vm.isLoading,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.price",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " R " +
                                  _vm._s(_vm.formatCurrency(item.price)) +
                                  " "
                              ),
                              item.tax > 0
                                ? _c("span", [
                                    _vm._v(
                                      "(R " +
                                        _vm._s(_vm.formatCurrency(item.tax)) +
                                        " VAT)"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" / " + _vm._s(item.unitOfMeasure) + " "),
                            ]
                          },
                        },
                        {
                          key: "item.photo",
                          fn: function ({ item }) {
                            return [
                              item.photo
                                ? _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      stretch: "",
                                      src: item.photo,
                                      width: 150,
                                      height: 150,
                                    },
                                  })
                                : _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      stretch: "",
                                      src: require("../assets/default-product-image.png"),
                                      width: 150,
                                      height: 150,
                                    },
                                  }),
                            ]
                          },
                        },
                        {
                          key: "item.amount",
                          fn: function ({ item }) {
                            return [
                              _c("QuantityButtonBasic", {
                                ref: "quantity",
                                model: {
                                  value: item.amount,
                                  callback: function ($$v) {
                                    _vm.$set(item, "amount", $$v)
                                  },
                                  expression: "item.amount",
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item.favourite",
                          fn: function ({ item }) {
                            return [
                              _vm.activeCustomer
                                ? _c("FavouriteIcon", {
                                    attrs: {
                                      identifier:
                                        item?.stockItem?.itemCode?.toString(),
                                      name: `stockItem-${_vm.activeCustomer.customerCode}`,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit("favouriteChange")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary--text w-100",
                                  staticStyle: {
                                    "font-size": "1em !important",
                                  },
                                  attrs: {
                                    "data-cy": "add-to-cart",
                                    height: "40",
                                    block: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addItem(item)
                                    },
                                  },
                                },
                                [_vm._v(" + Add to Cart ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1599638811
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.resultType === "grid"
        ? _c("CardIteratorStockItems", {
            attrs: {
              loading: _vm.isLoading,
              stockItems: _vm.availableItems,
              "no-data-text": "No results found",
            },
            on: { change: _vm.updateStockItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }