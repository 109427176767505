var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("StockItemsLoading") : _vm._e(),
      _c("CardListCategories", {
        attrs: {
          categories: _vm.categories,
          depth: _vm.depth,
          "hide-overlay": "",
        },
        on: { change: _vm.updateCategory },
      }),
      !_vm.isAdmin && !_vm.isAgent ? _c("SoftSaleDialog") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }