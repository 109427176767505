var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.isLoggedIn ? _c("CartService") : _vm._e(),
      _vm.isLoggedIn ? _c("FavouriteService") : _vm._e(),
      _c("NavDrawer", {
        attrs: { drawer: _vm.drawer },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
      _vm.isLoggedIn && !_vm.isInit ? _c("SyncService") : _vm._e(),
      _c(
        "v-app-bar",
        {
          staticClass: "slide-from-top",
          attrs: { fixed: "", app: "", flat: "", color: "white" },
        },
        [
          _vm.showBackButton
            ? _c("div", { staticClass: "back-button mr-2" }, [
                _c("img", {
                  staticClass: "icon mt-1",
                  attrs: { src: require("@/assets/icon-back.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                }),
              ])
            : _vm._e(),
          _c(
            "v-toolbar-title",
            { staticClass: "text-h3", attrs: { "data-cy": "toolbar-title" } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("v-spacer"),
          _vm.isWeb ? _c("InstallButton") : _vm._e(),
          _c("div", { staticClass: "mr-2" }),
          _c("FeedbackDialog"),
          _c("div", { staticClass: "mr-2" }),
          _c(
            "div",
            { staticStyle: { "min-width": "20vw" } },
            [_c("GlobalSearch", { attrs: { type: _vm.searchType } })],
            1
          ),
          _c("div", { staticClass: "ml-2" }),
          _c("Orders"),
          _c("MiniCart"),
          _c("div", { staticClass: "font-weight-bold mr-2 ml-2" }, [
            _vm._v(" " + _vm._s(_vm.displayNameOrUsername) + " "),
          ]),
          _c("Account"),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("MaintenanceNotify", {
            attrs: { showMaintenanceWarning: _vm.showMaintenanceWarning },
          }),
          _vm.isLoggedIn ? _c("OnHoldCustomer") : _vm._e(),
          _c("keep-alive", { attrs: { include: [] } }, [_c("router-view")], 1),
          _c("div", { staticStyle: { height: "80px", width: "100%" } }),
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-top": "36px" } }),
      _c("Auth"),
      _c("Toasts", { attrs: { text: _vm.message, color: "success" } }),
      _c("Toasts", { attrs: { text: _vm.error, color: "danger" } }),
      _c("BasicToasts"),
      _c("StickyToasts"),
      _c("RateOurService", {
        attrs: { show: _vm.showRating },
        on: {
          close: function ($event) {
            _vm.showRating = false
          },
        },
      }),
      _c("NetworkService"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }