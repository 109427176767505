var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        "data-cy": "snackbar",
        color: _vm.color,
        "multi-line": _vm.mode === "multi-line",
        timeout: _vm.timeout,
        vertical: _vm.mode === "vertical",
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { dark: "", text: "" },
                    on: {
                      click: function ($event) {
                        _vm.snackbar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [_vm._v(" " + _vm._s(_vm.text) + " "), _c("v-divider")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }