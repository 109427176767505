var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass:
                "d-flex align-stretch col-sm-padding stock-item-card",
              attrs: {
                cols: "12",
                xs: "12",
                sm: "6",
                md: "4",
                lg: "3",
                xl: "2",
              },
            },
            [
              _vm.isLoading ? _c("StockItemsLoading") : _vm._e(),
              _c("StockItemCard", {
                attrs: {
                  stockItem: _vm.stockItem,
                  autoFocus: true,
                  showAlternative: true,
                },
                on: { add: _vm.add, change: _vm.update },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }