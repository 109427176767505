var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 d-flex align-center flex-column",
      staticStyle: { "margin-bottom": "100px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-h5 text-bold fade-in text-center",
          class: { "text--left": _vm.isMobile },
        },
        [_vm._v(" Welcome, " + _vm._s(_vm.displayNameOrUsername) + "! ")]
      ),
      _c("div", { staticClass: "text-h6 fade-in text-center" }, [
        _vm._v(" Where would you like to go? "),
      ]),
      _c(
        "v-row",
        {
          staticClass: "d-flex",
          staticStyle: { width: "75vw" },
          attrs: { "data-cy": "ShopHome-menu-item" },
        },
        _vm._l(_vm.menuItems, function (item) {
          return _c(
            "v-col",
            {
              key: item.name,
              staticClass: "col-lg-padding home-menu-item",
              attrs: { cols: "12", md: "4" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "d-flex fill-height",
                  attrs: { to: { name: item.to } },
                },
                [
                  _c("img", {
                    staticClass: "w-100",
                    attrs: { src: item.imageUrl },
                  }),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }