var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiHome))]), _vm._v(" Home Page ")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }