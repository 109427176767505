var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading || _vm.isSaving } },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c(
            "v-card-text",
            _vm._l(_vm.availableItems, function (item, index) {
              return _c(
                "v-row",
                {
                  key: item.id,
                  staticClass:
                    "d-flex justify-start align-center border-bottom-1",
                  class: { "border-top-1": index === 0 },
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: item.stockItem?.photo,
                          contain: "",
                          height: 100,
                          width: 100,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "1" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.amount) + " x "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.itemCode) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.stockItem?.description) + " "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.pick(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.type) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "mt-5",
          attrs: { loading: _vm.isLoading || _vm.isSaving },
        },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" Completed Items "),
          ]),
          _c(
            "v-card-text",
            _vm._l(_vm.picked, function (item, index) {
              return _c(
                "v-row",
                {
                  key: item.id,
                  staticClass:
                    "d-flex justify-start align-center border-bottom-1",
                  class: { "border-top-1": index === 0 },
                  attrs: { dense: "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: item.stockItem?.photo,
                          contain: "",
                          height: 100,
                          width: 100,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "1" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.amount) + " x "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.itemCode) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(" " + _vm._s(item.stockItem?.description) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("div", { staticClass: "text-h6 mr-5" }, [
                      _vm._v(
                        " Picked at " +
                          _vm._s(_vm.formatDateTime(item.pickedItem.created)) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }