var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
      _vm.order && _vm.activeCustomer
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center",
                    class: {
                      "justify-start": _vm.isDesktop,
                      "justify-center": _vm.isMobile,
                      "text-center": _vm.isMobile,
                      "flex-column": _vm.isMobile,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../assets/check.png"),
                        height: "60",
                        width: "60",
                      },
                    }),
                    _c("b", { staticClass: "text-large mr-2" }, [
                      _vm._v("Thank you for your order!"),
                    ]),
                  ]
                ),
                _vm.$vuetify.breakpoint.mobile
                  ? _c("div", [
                      _c("div", { staticClass: "text-medium text-center" }, [
                        _vm._v(
                          " Your order has been conﬁrmed and scheduled for delivery on "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.order.deliveryDate))]),
                        _vm._v(
                          ". Your items will be delivered to the following address: "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.address))]),
                      ]),
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "text-large mt-5" }, [
                        _vm._v(
                          " Your order has been conﬁrmed and scheduled for delivery on: "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "text-large font-weight-bold" },
                        [_vm._v(_vm._s(_vm.order.deliveryDate))]
                      ),
                      _vm.address
                        ? _c("div", { staticClass: "text-large mt-5" }, [
                            _vm._v(
                              " Your items will be delivered to the following address: "
                            ),
                          ])
                        : _vm._e(),
                      _vm.address
                        ? _c(
                            "div",
                            { staticClass: "text-large font-weight-bold" },
                            [_vm._v(_vm._s(_vm.address))]
                          )
                        : _vm._e(),
                    ]),
                _c(
                  "div",
                  { staticClass: "text-medium background--grey pa-5 mt-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                      },
                      [
                        _c("span", [_vm._v("Customer Code:")]),
                        _c("span", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(_vm.activeCustomer.customerDesc)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                      },
                      [
                        _c("span", [_vm._v("Customer Order No:")]),
                        _c("span", { staticClass: "text-right" }, [
                          _vm._v(_vm._s(_vm.order.orderNo)),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.order.notes.length > 0
                  ? _c(
                      "div",
                      { staticClass: "text-medium background--grey pa-5 mt-5" },
                      [
                        _c(
                          "div",
                          { staticClass: "text-medium font-weight-bold" },
                          [_vm._v(" Notes: ")]
                        ),
                        _c("div", { staticClass: "text-medium" }, [
                          _vm._v(" " + _vm._s(_vm.order.notes) + " "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "v-card",
                    { attrs: { color: "#eeeeee", elevation: "0" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h4",
                          attrs: { "primary-title": "" },
                        },
                        [_vm._v(" Order Summary ")]
                      ),
                      _c("v-card-text", [
                        _c("table", { staticClass: "table-summary" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Description")]),
                              _c("th", [_vm._v("Qty")]),
                              _c("th", [_vm._v("Price")]),
                              !_vm.$vuetify.breakpoint.mobile
                                ? _c("th", [_vm._v("Tax")])
                                : _vm._e(),
                              !_vm.$vuetify.breakpoint.mobile
                                ? _c("th", [_vm._v("Total")])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.displayCart, function (item) {
                              return _c("tr", { key: item.id }, [
                                _c("td", [
                                  _c("b", [
                                    _vm._v(_vm._s(item.stockItem?.description)),
                                  ]),
                                ]),
                                _c("td", [
                                  _c("span", [_vm._v(_vm._s(item.amount))]),
                                ]),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      "R" +
                                        _vm._s(_vm.formatCurrency(item.price))
                                    ),
                                  ]),
                                ]),
                                !_vm.$vuetify.breakpoint.mobile
                                  ? _c("td", [
                                      _c("span", [
                                        _vm._v(
                                          "R" +
                                            _vm._s(_vm.formatCurrency(item.tax))
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                !_vm.$vuetify.breakpoint.mobile
                                  ? _c("td", [
                                      _c("span", [
                                        _vm._v(
                                          "R" +
                                            _vm._s(
                                              _vm.formatCurrency(item.total)
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                          _c("tfoot", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: _vm.$vuetify.breakpoint.mobile
                                      ? 2
                                      : 4,
                                  },
                                },
                                [_c("b", [_vm._v("Subtotal")])]
                              ),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    "R" +
                                      _vm._s(_vm.formatCurrency(_vm.subTotal))
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: _vm.$vuetify.breakpoint.mobile
                                      ? 2
                                      : 4,
                                  },
                                },
                                [_c("b", [_vm._v("VAT")])]
                              ),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    "R" + _vm._s(_vm.formatCurrency(_vm.vat))
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  attrs: {
                                    colspan: _vm.$vuetify.breakpoint.mobile
                                      ? 2
                                      : 4,
                                  },
                                },
                                [_c("b", [_vm._v("Total")])]
                              ),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    "R" + _vm._s(_vm.formatCurrency(_vm.total))
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text",
                      attrs: {
                        large: !_vm.$vuetify.breakpoint.mobile,
                        block: _vm.$vuetify.breakpoint.mobile,
                        color: "accent",
                        elevation: "0",
                        to: { name: "shop-ShopCategories" },
                      },
                    },
                    [_vm._v(" Continue shopping ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading ? _c("v-row") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }