var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isInstalled && _vm.deferredPrompt
    ? _c(
        "v-btn",
        {
          attrs: { color: "primary", elevation: "0", height: "48" },
          on: {
            click: function ($event) {
              return _vm.installPWA()
            },
          },
        },
        [_vm._v(" Install ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }