<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">
        Add Sales Order Line
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        Add a Sales Order Line
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">Order Number: {{ salesOrder.id }}</v-col>
        </v-row>
        <v-form>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="salesOrderLine.itemCode"
                :loading="isLoading"
                data-cy="DialogAddSalesOrderLine-itemCode"
                label="Stock Item"
                :item-text="i => `${i.itemCode} - ${i.description}`"
                item-value="itemCode"
                :items="stockItems"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="salesOrderLine.quantity"
                label="Quantity"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="dialog = false">Cancel</v-btn>
        <v-btn @click="onSubmit" color="success">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DialogAddSalesOrderLine',
  mixins: [loading],
  props: {
    salesOrder: Object,
  },
  data() {
    return {
      dialog: false,
      salesOrderLine: {
        itemCode: '',
        quantity: 0,
      },
    };
  },
  computed: {
    ...mapState('stockItems', ['stockItems']),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_stockItems']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_stockItems();
      this.isLoading = false;
    },
    onSubmit() {
      const stockItem = this.stockItems.find((i) => i.itemCode === this.salesOrderLine.itemCode);
      this.$emit('add', {
        itemCode: stockItem.itemCode,
        itemDescription: stockItem.description,
        quantity: this.salesOrderLine.quantity,
        unitOfMeasure: stockItem.unitOfMeasure,
        binLocation: stockItem.binLocation,
      });
      this.dialog = false;
    },
  },
};
</script>
