var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex-container",
      attrs: { "data-cy": "quantity-button-basic" },
    },
    [
      _c(
        "button",
        {
          staticClass: "button-blocks button-blocks-left",
          attrs: {
            disabled: _vm.localValue === 0,
            "data-cy": "quantity-down",
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.countDown()
            },
          },
        },
        [_c("span", { staticClass: "text-sizing" }, [_vm._v("-")])]
      ),
      _c("div", { staticClass: "quantity-block" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.localValue,
              expression: "localValue",
              modifiers: { number: true },
            },
          ],
          ref: "quantityInput",
          attrs: { type: "number", "data-cy": "quantity-input" },
          domProps: { value: _vm.localValue },
          on: {
            blur: [
              _vm.quantityBlurred,
              function ($event) {
                return _vm.$forceUpdate()
              },
            ],
            focus: _vm.quantityClicked,
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.localValue = _vm._n($event.target.value)
              },
              _vm.quantityChanged,
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.$emit("add")
            },
          },
        }),
      ]),
      _c(
        "button",
        {
          staticClass: "button-blocks button-blocks-right",
          attrs: { "data-cy": "quantity-up", type: "button" },
          on: {
            click: function ($event) {
              return _vm.countUp()
            },
          },
        },
        [_c("span", { staticClass: "text-sizing" }, [_vm._v("+")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }