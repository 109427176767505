var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { fluid: "" } },
    [
      _c("v-data-iterator", {
        attrs: {
          id: "data-operator",
          "data-cy": "favourites-list",
          loading: _vm.isLoading,
          items: _vm.stockItems,
          "items-per-page": 24,
          "footer-props": _vm.footerProps,
          "hide-footer": _vm.stockItems.length < 24,
          search: _vm.search,
          "no-data-text": "No favourites found",
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                        attrs: {
                          cols: "12",
                          "offset-xl": "6",
                          xl: "6",
                          lg: "10",
                          "offset-lg": "2",
                        },
                      },
                      [
                        _vm.isInRole("Administrators")
                          ? _c("ImportFavourites", {
                              on: { imported: _vm.refreshFavourites },
                            })
                          : _vm._e(),
                        _vm.divisions.length > 0
                          ? _c("div", { staticClass: "mr-2" })
                          : _vm._e(),
                        _vm.divisions.length > 0
                          ? _c("DivisionFilter")
                          : _vm._e(),
                        _c("div", { staticClass: "mr-2" }),
                        _c("ExportToExcelButton", {
                          attrs: {
                            name: "Favourites",
                            data: _vm.exportFavourites,
                            height: "48",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ items }) {
              return [
                _c(
                  "v-row",
                  { staticClass: "d-flex" },
                  _vm._l(items, function (stockItem, index) {
                    return _c(
                      "v-col",
                      {
                        key: `stockItem-${index}`,
                        staticClass:
                          "d-flex align-stretch col-sm-padding stock-item-card",
                        attrs: {
                          cols: "12",
                          xs: "12",
                          sm: "6",
                          md: "4",
                          lg: "3",
                          xl: "3",
                          "data-cy": `stock-item-${index}`,
                        },
                      },
                      [
                        _c("StockItemCard", {
                          attrs: {
                            stockItem: stockItem,
                            showAlternative: true,
                          },
                          on: {
                            add: _vm.add,
                            change: _vm.change,
                            favouriteChange: function ($event) {
                              return _vm.$emit("favouriteChange")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }