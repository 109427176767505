var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "fixed-breadcrumbs": _vm.$vuetify.breakpoint.mobile } },
    [
      _vm.history.length > 0 &&
      !_vm.isLoading &&
      !_vm.$vuetify.breakpoint.mobile
        ? _c("v-breadcrumbs", {
            attrs: {
              id: "bread-crumbs",
              large: !_vm.$vuetify.breakpoint.mobile,
              items: _vm.history,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-breadcrumbs-item",
                        {
                          staticClass: "breadcrumb",
                          attrs: { href: item.href, disabled: item.disabled },
                        },
                        [_vm._v(" " + _vm._s(item.text) + " ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3956920481
            ),
          })
        : _c(
            "div",
            { staticClass: "mt-2 mb-2 ml-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "#EE1C25", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "secondary" } },
                    [_vm._v(_vm._s(_vm.mdiUndo))]
                  ),
                  _c("span", { staticClass: "secondary--text" }, [
                    _vm._v("Back"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }