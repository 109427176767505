var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-combobox", {
    attrs: {
      items: _vm.localUsers,
      chips: "",
      clearable: "",
      label: _vm.label,
      multiple: "",
      "prepend-icon": _vm.mdiMail,
      solo: "",
      "item-text": "email",
      "item-value": "email",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ attrs, item, select, selected }) {
          return [
            _c(
              "v-chip",
              _vm._b(
                {
                  attrs: { "input-value": selected, close: "" },
                  on: {
                    click: select,
                    "click:close": function ($event) {
                      return _vm.remove(item)
                    },
                  },
                },
                "v-chip",
                attrs,
                false
              ),
              [_c("strong", [_vm._v(_vm._s(item.email))])]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedUsers,
      callback: function ($$v) {
        _vm.selectedUsers = $$v
      },
      expression: "selectedUsers",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }