var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-iterator", {
    ref: "dataTable",
    attrs: {
      id: "data-operator",
      items: _vm.stockItems,
      "items-per-page": 12,
      page: _vm.pageNumber,
      "hide-default-footer": "",
      search: _vm.search,
      "data-cy": "favourites-list",
      "no-results-text": "",
      "no-data-text": "No favourites found",
      "footer-props": {
        "disable-items-per-page": true,
        "items-per-page-options": [],
      },
    },
    on: {
      "update:page": function ($event) {
        return _vm.$vuetify.goTo(_vm.$refs.dataTable, _vm.goToOptions)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            _c("p", { staticClass: "ml-5" }, [_vm._v("No favourites found")]),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-row",
              {
                staticClass: "mt-1 mb-1 mr-3 ml-3",
                attrs: { justify: "space-between" },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2 slide-in-left",
                    attrs: {
                      small: "",
                      disabled: _vm.pageNumber === 1,
                      color: "success",
                    },
                    on: { click: _vm.prevPage },
                  },
                  [_vm._v(" Previous ")]
                ),
                _c("span", [
                  _vm._v(
                    " Page " +
                      _vm._s(_vm.pageNumber) +
                      " of " +
                      _vm._s(_vm.pageCount) +
                      " "
                  ),
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "slide-in",
                    attrs: {
                      small: "",
                      color: "primary",
                      disabled: _vm.pageNumber >= _vm.pageCount,
                    },
                    on: { click: _vm.nextPage },
                  },
                  [_vm._v("Next")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function ({ items }) {
          return _vm._l(items, function (stockItem, index) {
            return _c(
              "div",
              {
                key: `stockItem-${index}`,
                staticClass: "mobile-favourite-stock-item",
              },
              [
                _c("MobileFavouriteStockItem", {
                  attrs: {
                    "data-cy": `stock-item-${index}`,
                    index: index,
                    showFavourites: true,
                    stockItem: stockItem,
                  },
                  on: {
                    add: _vm.add,
                    favouriteChange: function ($event) {
                      return _vm.$emit("favouriteChange")
                    },
                  },
                }),
                _c("hr"),
              ],
              1
            )
          })
        },
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "v-row",
              {
                staticClass: "mt-1 mb-5 mr-3 ml-3",
                attrs: { justify: "space-between" },
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: "",
                      disabled: _vm.pageNumber === 1,
                      color: "success",
                    },
                    on: { click: _vm.prevPage },
                  },
                  [_vm._v(" Previous ")]
                ),
                _c("span", [
                  _vm._v(
                    " Page " +
                      _vm._s(_vm.pageNumber) +
                      " of " +
                      _vm._s(_vm.pageCount) +
                      " "
                  ),
                ]),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      disabled: _vm.pageNumber >= _vm.pageCount,
                      color: "primary",
                    },
                    on: { click: _vm.nextPage },
                  },
                  [_vm._v("Next")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }