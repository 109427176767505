var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("CartService"),
      _c("FavouriteService"),
      _c("NavDrawer", {
        attrs: { drawer: _vm.drawer },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
      _vm.isLoggedIn && !_vm.isInit ? _c("SyncService") : _vm._e(),
      _c(
        "v-app-bar",
        {
          staticClass: "d-flex align-center w-100 mobile-app-bar",
          attrs: { color: "white", height: "60", fixed: "", flat: "", app: "" },
        },
        [
          _vm.showBackButton
            ? _c("div", { staticClass: "mr-2" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/icon-arrow-left.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                }),
              ])
            : _c("img", {
                staticClass: "icon mr-2 slide-in-left",
                attrs: { src: require("@/assets/icon-menu.svg") },
                on: {
                  click: function ($event) {
                    _vm.drawer = !_vm.drawer
                  },
                },
              }),
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between w-100 slide-from-top",
            },
            [
              !_vm.expandedSearch
                ? _c(
                    "v-toolbar-title",
                    {
                      staticClass:
                        "text-h4 text-center app-bar-title flex-grow-1",
                      attrs: { "data-cy": "toolbar-title" },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  )
                : _c(
                    "div",
                    { staticClass: "d-flex align-center flex-grow-1" },
                    [
                      _c("GlobalSearch", {
                        attrs: {
                          type: _vm.isFavourites ? "Favourites" : "All",
                        },
                      }),
                    ],
                    1
                  ),
              !_vm.expandedSearch
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        color: "transparent",
                        "data-cy": "expand-search",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "grey" },
                          on: { click: _vm.openSearch },
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiMagnify) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.expandedSearch
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        color: "transparent",
                        "data-cy": "clear-expand-search",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "grey" },
                          on: { click: _vm.closeSearch },
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiCloseCircle) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-main",
        { staticClass: "mobile-main" },
        [
          _c("OnHoldCustomer"),
          _c("MaintenanceNotify", {
            attrs: { "show-maintenance-warning": "" },
          }),
          _c("router-view"),
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-top": "36px" } }),
      _c(
        "v-footer",
        { attrs: { color: "white", padless: "", fixed: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mobile-nav-item", attrs: { "no-gutters": "" } },
            _vm._l(_vm.menuItems, function (item) {
              return _c(
                "v-col",
                { key: item.name, attrs: { cols: "3" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "d-block h-100 black--text text-decoration-none",
                      attrs: { to: { name: item.pathName } },
                      on: {
                        click: function ($event) {
                          _vm.selectedMenu = item.name
                        },
                      },
                    },
                    [
                      item.isCart
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-column align-center" },
                            [
                              _c(
                                "v-badge",
                                {
                                  staticClass:
                                    "d-flex flex-column align-center mt-1",
                                  attrs: { right: "", color: "success" },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "badge" }, slot: "badge" },
                                    [_vm._v(_vm._s(_vm.total_stockItems))]
                                  ),
                                  _c("img", {
                                    staticClass: "icon icon-selected",
                                    attrs: { src: item.iconSelected },
                                  }),
                                  _c("img", {
                                    staticClass: "icon icon-unselected",
                                    attrs: { src: item.icon },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mt-2",
                                      class: {
                                        selected:
                                          _vm.selectedMenu === item.name,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(item.name)) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "d-flex flex-column align-center" },
                            [
                              _c("img", {
                                staticClass: "icon icon-selected",
                                attrs: { src: item.iconSelected },
                              }),
                              _c("img", {
                                staticClass: "icon icon-unselected",
                                attrs: { src: item.icon },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "mt-2",
                                  class: {
                                    selected: _vm.selectedMenu === item.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                              ),
                            ]
                          ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("Auth"),
      _c("Toasts", { attrs: { text: _vm.message, color: "success" } }),
      _c("Toasts", { attrs: { text: _vm.error, color: "danger" } }),
      _c("BasicToasts"),
      _c("StickyToasts"),
      _c("RateOurService", {
        attrs: { show: _vm.showRating },
        on: {
          close: function ($event) {
            _vm.showRating = false
          },
        },
      }),
      _c("NetworkService"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }