var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "####-##-##",
                            expression: "'####-##-##'",
                          },
                        ],
                        staticClass: "date-picker",
                        attrs: {
                          label: _vm.label,
                          rules: _vm.combinedRules,
                          clearable: _vm.clearable,
                          filled: _vm.filled,
                          "append-icon": _vm.mdiCalendar,
                          readonly: _vm.disableEdit,
                          disabled: _vm.disabled,
                          "background-color": "white",
                          solo: _vm.solo,
                          outlined: _vm.outlined,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", _vm.localDate)
                          },
                          "click:append": function ($event) {
                            _vm.localOpenModal = true
                          },
                        },
                        model: {
                          value: _vm.localDate,
                          callback: function ($$v) {
                            _vm.localDate = $$v
                          },
                          expression: "localDate",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.localOpenModal,
            callback: function ($$v) {
              _vm.localOpenModal = $$v
            },
            expression: "localOpenModal",
          },
        },
        [
          _c("v-date-picker", {
            attrs: { "allowed-dates": _vm.allowedDates },
            on: {
              input: function ($event) {
                return _vm.inputDate()
              },
            },
            model: {
              value: _vm.localDate,
              callback: function ($$v) {
                _vm.localDate = $$v
              },
              expression: "localDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }