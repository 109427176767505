var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeCustomer
    ? _c(
        "div",
        [
          _c("v-text-field", {
            ref: "textField",
            attrs: {
              "data-cy": "text-deliveryAddress",
              label: "Delivery Address",
              filled: "",
              value: _vm.activeCustomer.delAddress01,
              readonly: "",
              "background-color": "white",
              solo: _vm.solo,
              outlined: _vm.outlined,
            },
          }),
          _c(
            "div",
            { staticClass: "ml-3", attrs: { "data-cy": "selected-address" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.activeCustomer.delAddress02))]),
              _c("p", [_vm._v(_vm._s(_vm.activeCustomer.delAddress03))]),
              _c("p", [_vm._v(_vm._s(_vm.activeCustomer.delAddress04))]),
              _c("p", [_vm._v(_vm._s(_vm.activeCustomer.delAddress05))]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }