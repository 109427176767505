var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "d-flex flex-column", staticStyle: { width: "100%" } },
    [
      _c("v-card-title", { staticClass: "shop-title" }, [
        _vm._v(" " + _vm._s(_vm.stockItem.stockItem.description) + " "),
      ]),
      _c("v-img", {
        attrs: { contain: "", src: _vm.detail.img, "max-height": "380" },
      }),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticStyle: { padding: "0", "padding-left": "12px" },
                  attrs: { cols: "12" },
                },
                [
                  _vm._v(
                    " R " +
                      _vm._s(_vm.formatCurrency(_vm.stockItem.price)) +
                      " "
                  ),
                  _vm.detail.tax > 0
                    ? _c("span", [
                        _vm._v(
                          "(R " +
                            _vm._s(_vm.formatCurrency(_vm.detail.tax)) +
                            " VAT)"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" / " + _vm._s(_vm.stockItem.unitOfMeasure) + " "),
                ]
              ),
              _vm.detail.qty > 0
                ? _c(
                    "v-col",
                    {
                      staticStyle: { padding: "0", "padding-left": "12px" },
                      attrs: { cols: "12" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.stockItem.stockItem.quantity) +
                          " items in stock "
                      ),
                    ]
                  )
                : _c(
                    "v-col",
                    {
                      staticStyle: { padding: "0", "padding-left": "12px" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", { staticClass: "red--text" }, [
                        _vm._v("Out of stock"),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-text-field", {
            ref: "quantity",
            attrs: { label: "Quantity", value: _vm.stockItem.amount },
            on: {
              input: function ($event) {
                return _vm.CHANGE_AMOUNT({
                  itemCode: _vm.stockItem.stockItem.itemCode,
                  amount: $event,
                })
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { small: "", color: "error" },
              on: {
                click: function ($event) {
                  return _vm.REMOVE_CART(_vm.stockItem.stockItem.itemCode)
                },
              },
            },
            [_vm._v(" Remove ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }