var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.salesOrder
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Order Date:")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.formatDate(_vm.salesOrder.orderDate))
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("To:")]),
                      _c("td", [_vm._v(_vm._s(_vm.salesOrder.customerName))]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.salesOrder.deliveryAddress1)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.salesOrder.deliveryAddress2)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.salesOrder.deliveryAddress3)),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td"),
                      _c("td", [
                        _vm._v(_vm._s(_vm.salesOrder.deliveryAddress4)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Delivery Date:")]),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.formatDate(_vm.salesOrder.orderDate))
                        ),
                      ]),
                    ]),
                    _c("tr", [_c("td", [_vm._v("Route:")]), _c("td")]),
                    _c("tr", [
                      _c("td", [_vm._v("Version:")]),
                      _c("td", [_vm._v(_vm._s(_vm.salesOrder.printCount))]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.salesOrder
        ? _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "4" } },
                [
                  _vm._v("Order Number: "),
                  _c("b", [_vm._v(_vm._s(_vm.salesOrder.id))]),
                ]
              ),
              _c("v-col", { staticClass: "text-right", attrs: { cols: "4" } }, [
                _vm._v("User: " + _vm._s(_vm.username)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("table", { staticClass: "table-picking" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(" Code ")]),
                  _c("th", [_vm._v(" Description ")]),
                  _c("th", [_vm._v(" Qty ")]),
                  _c("th", [_vm._v(" Unit ")]),
                  _c("th", [_vm._v(" Bin ")]),
                  _c("th", [_vm._v(" Printer ")]),
                  _c("th", [_vm._v(" Picked ")]),
                  _c("th"),
                ]),
              ]),
              _vm.lines.length > 0
                ? _c(
                    "tbody",
                    _vm._l(_vm.lines, function (line, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(line.itemCode))]),
                        _c("td", [_vm._v(_vm._s(line.itemDescription))]),
                        _c("td", [_vm._v(_vm._s(line.originalQuantity))]),
                        _c("td", [_vm._v(_vm._s(line.unitOfMeasure))]),
                        _c("td", [_vm._v(_vm._s(line.bin))]),
                        _c("td", [_vm._v(_vm._s(line.printer))]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: line.quantity,
                                expression: "line.quantity",
                                modifiers: { number: true },
                              },
                            ],
                            attrs: { type: "number" },
                            domProps: { value: line.quantity },
                            on: {
                              focus: _vm.onPickedFocus,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  line,
                                  "quantity",
                                  _vm._n($event.target.value)
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeLine(line)
                                  },
                                },
                              },
                              [_vm._v(" Delete ")]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.salesOrder
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("PrintPickingSlip", {
                    attrs: { "sales-order": _vm.salesOrder, lines: _vm.lines },
                    on: { updatePrintCount: _vm.updatePrintCount },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "flex flex-column align-end", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-5",
                  attrs: { block: "", color: "accent", loading: _vm.isSaving },
                  on: { click: _vm.save },
                },
                [_vm._v("Save")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "success", loading: _vm.isSaving },
                  on: { click: _vm.complete },
                },
                [_vm._v("Complete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }