var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { attrs: { "primary-title": "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "white", color: "primary" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "v-tab",
                    {
                      key: tab,
                      on: {
                        click: function ($event) {
                          _vm.currentTab = tab
                        },
                      },
                    },
                    [_vm._v(_vm._s(tab))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "v-tab-item",
                    { key: tab },
                    [
                      _c(tab.replace(" ", "-").toLowerCase(), {
                        tag: "component",
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("BookingNotification"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }