var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        color: "primary",
        permanent: !_vm.$vuetify.breakpoint.mdAndDown,
      },
      model: {
        value: _vm.localDrawer,
        callback: function ($$v) {
          _vm.localDrawer = $$v
        },
        expression: "localDrawer",
      },
    },
    [
      _c(
        "v-list-item",
        { attrs: { to: { name: "shop-ShopHome" } } },
        [
          _c("v-list-item-content", [
            _c("img", {
              attrs: { src: require("@/assets/nav-logo.png"), width: "100%" },
            }),
          ]),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _vm.showCustomerSelector
                ? _c("active-customer")
                : _c("active-restaurant-admin"),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        _vm._l(_vm.menuItems, function (menuItem) {
          return _c("div", { key: menuItem.name }, [
            _c("img", { attrs: { src: menuItem.icon } }),
            _c("img", { attrs: { src: menuItem.iconSelected } }),
          ])
        }),
        0
      ),
      _c("MenuDrawer", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }