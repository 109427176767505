var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "printArea" }, [
      _c("div", [
        _c("h3", [_vm._v("Company Delivery Details:")]),
        _c("p", [_vm._v(_vm._s(_vm.salesOrder.customerName))]),
        _c("p", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress1))]),
        _c("p", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress2))]),
        _c("p", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress3) + ",")]),
        _c("p", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress4))]),
      ]),
      _c("div", [
        _c("p", [
          _vm._v("Dispatch Date: " + _vm._s(_vm.salesOrder.deliveryDate)),
        ]),
        _c("p", [_vm._v("Printed Date: " + _vm._s(new Date()))]),
        _c("p", [_vm._v("Page: 1")]),
      ]),
      _c(
        "table",
        { attrs: { border: "1", cellspacing: "0", cellpadding: "5" } },
        [
          _vm._m(0),
          _vm._l(_vm.lines, function (item) {
            return _c("tr", { key: item.code }, [
              _c("td", [_vm._v(_vm._s(item.code))]),
              _c("td", [_vm._v(_vm._s(item.description))]),
              _c("td", [_vm._v(_vm._s(item.quantity))]),
              _c("td", [_vm._v(_vm._s(item.unitOfMeasure))]),
              _c("td", [_vm._v(_vm._s(item.bin))]),
              _c("td", [_vm._v("________________________")]),
            ])
          }),
        ],
        2
      ),
      _vm._m(1),
    ]),
    _c("button", { on: { click: _vm.print } }, [_vm._v("Print")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Code")]),
      _c("th", [_vm._v("Description")]),
      _c("th", [_vm._v("Qty")]),
      _c("th", [_vm._v("Unit")]),
      _c("th", [_vm._v("Bin")]),
      _c("th", [_vm._v("Quantity (to be picked)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("Picker: ___________________________________________")]),
      _c("p", [_vm._v("Date: _____________________________________________")]),
      _c("p", [_vm._v("Signature: ________________________________________")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }