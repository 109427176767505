var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("DatePicker", {
                    attrs: {
                      filled: true,
                      "data-cy": "FormDriver-date",
                      label: _vm.$$t("date"),
                    },
                    model: {
                      value: _vm.localVehiclePart.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "date", $$v)
                      },
                      expression: "localVehiclePart.date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormVehiclePart-name",
                      label: _vm.$$t("name"),
                    },
                    model: {
                      value: _vm.localVehiclePart.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "name", $$v)
                      },
                      expression: "localVehiclePart.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormVehiclePart-partNumber",
                      label: _vm.$$t("partNumber"),
                    },
                    model: {
                      value: _vm.localVehiclePart.partNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "partNumber", $$v)
                      },
                      expression: "localVehiclePart.partNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormVehiclePart-price",
                      label: _vm.$$t("price"),
                    },
                    model: {
                      value: _vm.localVehiclePart.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "price", $$v)
                      },
                      expression: "localVehiclePart.price",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormVehiclePart-serialNumber",
                      label: _vm.$$t("serialNumber"),
                    },
                    model: {
                      value: _vm.localVehiclePart.serialNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "serialNumber", $$v)
                      },
                      expression: "localVehiclePart.serialNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      filled: "",
                      items: _vm.states,
                      "data-cy": "FormVehiclePart-state",
                      label: _vm.$$t("state"),
                    },
                    model: {
                      value: _vm.localVehiclePart.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "state", $$v)
                      },
                      expression: "localVehiclePart.state",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      "data-cy": "FormVehiclePart-supplierId",
                      filled: "",
                      label: _vm.$$t("supplierId"),
                      items: _vm.fleetSuppliers,
                      "item-text": "name",
                      "item-value": "id",
                    },
                    model: {
                      value: _vm.localVehiclePart.supplierId,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "supplierId", $$v)
                      },
                      expression: "localVehiclePart.supplierId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      "data-cy": "FormVehiclePart-vehicleId",
                      filled: "",
                      label: _vm.$$t("vehicleId"),
                      items: _vm.vehicles,
                      "item-text": (v) =>
                        `${v.make} ${v.model} (${v.licensePlate})`,
                      "item-value": "id",
                      disabled: "",
                    },
                    model: {
                      value: _vm.localVehiclePart.vehicleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.localVehiclePart, "vehicleId", $$v)
                      },
                      expression: "localVehiclePart.vehicleId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormVehiclePart-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }