var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "nav-list", attrs: { dense: "", nav: "" } },
    [
      _vm._l(_vm.items, function (menuItem, index) {
        return _c(
          "v-list-item",
          {
            key: menuItem.name,
            staticClass: "nav-item",
            class: { selected: menuItem.selected },
            attrs: { disabled: menuItem.selected },
            on: {
              click: function ($event) {
                return _vm.selectMenuItem(index, menuItem.pathName)
              },
            },
          },
          [
            _c("v-list-item-icon", [
              menuItem.selected
                ? _c("img", {
                    staticClass: "icon icon-small",
                    attrs: { src: menuItem.iconSelected },
                  })
                : _c("img", {
                    staticClass: "icon icon-small",
                    attrs: { src: menuItem.icon },
                  }),
              _vm._v(" " + _vm._s(_vm.$t(menuItem.name)) + " "),
            ]),
          ],
          1
        )
      }),
      _vm.isAdmin
        ? _c(
            "v-list-item",
            {
              staticClass: "nav-item",
              class: { selected: _vm.selectedIndex === _vm.items.length },
              on: {
                click: function ($event) {
                  return _vm.selectMenuItem(_vm.items.length, "app-Admin")
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-2 mr-4",
                      attrs: {
                        color:
                          _vm.selectedIndex === _vm.items.length
                            ? "accent"
                            : "white",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiTools) + " ")]
                  ),
                  _vm._v(" Admin "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-list-item",
        {
          staticClass: "nav-item",
          class: { selected: _vm.selectedIndex === _vm.items.length + 1 },
          on: {
            click: function ($event) {
              return _vm.selectMenuItem(_vm.items.length + 1, "app-Settings")
            },
          },
        },
        [
          _c("v-list-item-icon", [
            _vm.selectedIndex === _vm.items.length + 1
              ? _c("img", {
                  staticClass: "icon icon-small",
                  attrs: {
                    src: require("../assets/icon-settings-selected.svg"),
                  },
                })
              : _c("img", {
                  staticClass: "icon icon-small",
                  attrs: { src: require("../assets/icon-settings.svg") },
                }),
            _vm._v(" Settings "),
          ]),
        ],
        1
      ),
      _c(
        "v-list-item",
        { staticClass: "nav-item", on: { click: _vm.logout } },
        [
          _c("v-list-item-icon", [
            _c("img", {
              staticClass: "icon icon-small",
              attrs: { src: require("../assets/icon-logout.svg") },
            }),
            _vm._v(" " + _vm._s(_vm.$t("common.logoff")) + " "),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }