var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "3", loading: _vm.isLoading } },
                [
                  _c("v-card-title", [_vm._v(" Edit Quote ")]),
                  _vm.client
                    ? _c("v-card-subtitle", [_c("active-customer")], 1)
                    : _vm._e(),
                  _vm.quote
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-h6",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(" Order No: "),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.quote.order.orderNo)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-h6",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(" Delivery Date: "),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.quote.order.deliveryDate)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-h6",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _vm._v(" Requested By: "),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.quote.createdBy)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("TableQuoteCart", {
                                attrs: { cartItems: _vm.quoteCart },
                                on: {
                                  change: _vm.orderChanged,
                                  remove: _vm.removeItem,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "notes",
                                      fn: function () {
                                        return [
                                          _c("v-textarea", {
                                            staticClass: "mt-10",
                                            attrs: {
                                              disabled: "",
                                              outlined: "",
                                              label: "Notes",
                                              hint: "e.g. special delivery notes",
                                              "data-cy": "Quote-notes",
                                            },
                                            model: {
                                              value: _vm.quote.order.notes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.quote.order,
                                                  "notes",
                                                  $$v
                                                )
                                              },
                                              expression: "quote.order.notes",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "buttons",
                                      fn: function () {
                                        return [
                                          _vm.quote.approvedBy
                                            ? _c(
                                                "div",
                                                { staticClass: "text-h6" },
                                                [
                                                  _vm._v(
                                                    " This was already " +
                                                      _vm._s(
                                                        _vm.getBool(
                                                          _vm.quote.approved
                                                        )
                                                      ) +
                                                      " by " +
                                                      _vm._s(
                                                        _vm.quote.approvedBy
                                                      ) +
                                                      " at " +
                                                      _vm._s(
                                                        _vm.formatDateTime(
                                                          _vm.quote.dateApproved
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isOnHold ||
                                                          _vm.isDirty,
                                                        color: "error",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.update(
                                                            false
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Decline ")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isOnHold ||
                                                          _vm.isDirty,
                                                        "data-cy":
                                                          "Quote-button-checkout",
                                                        color: "success",
                                                        type: "submit",
                                                        loading: _vm.isSaving,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.update(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Approve ")]
                                                  ),
                                                  _vm.isDirty
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "data-cy":
                                                              "Quote-button-checkout",
                                                            color: "warning",
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: _vm.cancel,
                                                          },
                                                        },
                                                        [_vm._v(" Cancel ")]
                                                      )
                                                    : _vm._e(),
                                                  _vm.isDirty
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "data-cy":
                                                              "Quote-button-checkout",
                                                            color: "success",
                                                            type: "button",
                                                            loading:
                                                              _vm.isUpdating,
                                                          },
                                                          on: {
                                                            click: _vm.save,
                                                          },
                                                        },
                                                        [_vm._v(" Save ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3476063806
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm.quoteCart.length <= 0
                            ? _c(
                                "p",
                                { staticClass: "ma-5" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary--text mr-2 mb-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v(" fa-info ")]
                                  ),
                                  _vm._v(" Cart is empty "),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }