<template>
  <v-menu
    offset-y
    :bottom="!$vuetify.breakpoint.mobile"
    :content-class="$vuetify.breakpoint.mobile ? 'search-menu' : ''"
    :absolute="$vuetify.breakpoint.mobile"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        ref="searchBox"
        v-model="search"
        @blur="menu = false"
        @focus="menu = true"
        @input="menu = true"
        @change="menu = true"
        v-on="on"
        class="search-box"
        :loading="isLoading"
        data-cy="input-search"
        :clearable="!$vuetify.breakpoint.mobile"
        :label="searchType === 'All' ? 'Search Gourmet Foods' : 'Search Favourites'"
        solo
        flat
        hide-details
        @keydown.enter="onEnter"
        @click:clear="clearSearch"
      >
        <template v-slot:append>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                data-cy="toggle-search-type"
              >
                <v-img
                  v-if="search?.length > 0"
                  height="18"
                  width="18"
                  contain
                  src="../../../../assets/icon-search.svg"
                  @click="searchText"
                />
                <v-icon
                  v-else-if="searchType === 'All'">{{ mdiFeatureSearch }}</v-icon>
                <v-icon v-else>{{ mdiHeart }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item data-cy="toggle-search-type-all">
                <v-list-item-title>
                  <v-btn @click="searchType = 'All'">
                    <v-icon>{{ mdiFeatureSearch }}</v-icon>
                    <strong>All Products</strong>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item data-cy="toggle-search-type-favourites">
                <v-list-item-title>
                  <v-btn @click="searchType = 'Favourites'">
                    <v-icon class="mr-2">{{ mdiHeart }}</v-icon>
                    <strong>Favourites</strong>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-text-field>
    </template>
    <v-list v-if="availableItems.length > 0">
      <v-virtual-scroll
        :items="availableItems"
        item-height="64"
        height="300"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            :data-cy="`search-result-${index}`"
            @click="selectItem(item)"
          >
            <v-list-item-icon>
              <v-img
                v-if="item.photo"
                height="55"
                width="55"
                contain
                :src="item.photo"
                :lazy-src="item.photo"
              />
              <v-img
                v-else
                contain
                stretch
                src="../assets/default-product-image.png"
                lazy-src="../assets/default-product-image.png"
                height="55"
                width="55"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-list>
 </v-menu>
</template>

<script>
import {
  mdiCart, mdiHeart, mdiMagnify, mdiFeatureSearch,
} from '@mdi/js';
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import searchMixin from '../mixins/search';

export default {
  name: 'Search',
  mixins: [loading, searchMixin],
  props: {
    redirect: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'All',
    },
  },
  data: () => ({
    mdiCart,
    mdiHeart,
    mdiMagnify,
    mdiFeatureSearch,
    availableItems: [],
    menu: false,
    searchMenu: false,
    model: null,
    search: '',
    tab: null,
    searchType: 'All',
  }),
  computed: {
    ...mapGetters('webCategories', ['allCategories']),
    ...mapState('app', ['isOffline']),
    ...mapState('stockItems', ['stockItems', 'favouriteStockItems']),
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        this.searchType = val;
      },
    },
    model(val) {
      if (val?.itemCode) {
        if (this.searchType === 'All') {
          this.$router.push({ name: 'shop-StockItemDetail', query: { itemCode: val.itemCode } });
          this.search = null;
        } else if (this.redirect) {
          this.$router.push({ name: 'shop-Favourites', query: { itemCode: val.itemCode } });
          this.search = null;
        }
      }
      if (val?.webCategoryId) {
        this.$router.push({
          name: 'shop-ShopStockItemByCategory',
          query: {
            category: val.webCategoryId,
            categoryName: val.text,
            depth: val.webCategoryDepth,
          },
        });
        this.search = '';
      }
    },
    // eslint-disable-next-line func-names
    search(val) {
      const { searchType } = this;
      this.$log.debug('search val', val);
      this.$root.$emit('search', {
        type: searchType,
        text: val,
      });
      if (!this.redirect && searchType === 'Favourites') {
        this.$log.debug('skipping search as redirect is not set');
        return;
      }
      if (val?.length > 1 && val !== this.model?.text) {
        this.$log.debug('loading data');
        this.loadData();
      } else {
        this.$log.debug(`skipping search for ${val}`);
      }
    },
  },
  mounted() {
    this.isLoading = false;
    this.searchType = this.type;
  },
  methods: {
    // eslint-disable-next-line func-names
    loadData: debounce(async function () {
      this.isLoading = true;
      const { searchType } = this;
      if (this.search?.length > 0) {
        if (searchType === 'All') {
          await this.searchAll(this.search);
        } else {
          await this.searchFavourites(this.search);
        }
        this.isLoading = false;
      }
    }),
    async searchAll(val) {
      // this.availableItems = await this.searchLocalAll(val);
      // if (this.availableItems.length > 0) return;

      this.$log.debug('Searching all from web');
      const results = await this.$http.get(`odata/advancedSearch?searchString=${val}`);
      this.availableItems = results.data.value;
      this.$log.debug(`Found ${this.availableItems.length} results`);
      if (this.redirect && this.availableItems.length > 0) {
        this.$log.debug('showing menu');
        this.menu = true;
      }
    },
    async searchFavourites(val) {
      // this.availableItems = await this.searchLocalFavourites(val);
      // if (this.availableItems.length > 0) return;

      this.$log.debug('Searching favourites from web');
      const results = await this.$http.get(`odata/searchFavourites?searchString=${val}`);
      this.availableItems = results.data.value
        .filter((s) => s.description.toLowerCase().includes(val.toLowerCase()));
    },
    async searchLocalFavourites(val) {
      const stockItems = this.favouriteStockItems
        ?.filter((s) => s.description.toLowerCase().includes(val.toLowerCase())) ?? [];
      const stockItemResults = stockItems.map((s) => this.parseStockItemResult(s));
      return [
        ...stockItemResults,
      ];
    },
    async searchLocalAll(query) {
      let stockItemResults = await this.searchLocalProducts(query);
      stockItemResults = stockItemResults.map((s) => this.parseStockItemResult(s));
      const categoryResults = await this.searchLocalCategories(query);
      const results = [...stockItemResults, ...categoryResults];

      return results;
    },
    clearSearch() {
      this.availableItems = [];
      this.search = '';
    },
    searchText() {
      if (this.search?.length > 0) {
        const { search } = this;
        this.availableItems = [];
        this.search = '';
        this.$refs.searchBox.blur();
        this.$router.push({
          name: 'shop-ShopSearch',
          query: {
            search,
          },
        });
      }
    },
    onEnter() {
      this.searchText();
    },
    selectItem(item) {
      this.model = item;
    },
  },
};
</script>
