var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c("h1", [_vm._v("Supplier")]),
          _c("v-text-field", {
            attrs: {
              filled: "",
              "data-cy": "FormSupplier-name",
              label: _vm.$$t("name"),
            },
            model: {
              value: _vm.localSupplier.name,
              callback: function ($$v) {
                _vm.$set(_vm.localSupplier, "name", $$v)
              },
              expression: "localSupplier.name",
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormSupplier-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.$parent.isSaving,
                    "data-cy": "FormSupplier-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }