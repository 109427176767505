var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CapturePhoto", {
    attrs: { label: _vm.label },
    on: { newImage: _vm.upload },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }