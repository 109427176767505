var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("StockItemsLoading") : _vm._e(),
      !_vm.isLoading && _vm.categories.length > 0
        ? _c("CardListCategories", {
            attrs: { categories: _vm.categories, depth: _vm.depth + 1 },
            on: { change: _vm.updateCategory },
          })
        : _vm._e(),
      !_vm.isLoading && _vm.categories.length === 0
        ? _c("CardListStockItems", {
            attrs: { stockItems: _vm.stockItems },
            on: { change: _vm.update },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }