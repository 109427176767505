var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "6" } }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Order Date:")]),
              _c("td", [
                _vm._v(_vm._s(_vm.formatDate(_vm.salesOrder.orderDate))),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("Status:")]),
              _c("td", [_c("b", [_vm._v(_vm._s(_vm.salesOrder.status))])]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("To:")]),
              _c("td", [_vm._v(_vm._s(_vm.salesOrder.customerName))]),
            ]),
            _c("tr", [
              _c("td"),
              _c("td", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress1))]),
            ]),
            _c("tr", [
              _c("td"),
              _c("td", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress2))]),
            ]),
            _c("tr", [
              _c("td"),
              _c("td", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress3))]),
            ]),
            _c("tr", [
              _c("td"),
              _c("td", [_vm._v(_vm._s(_vm.salesOrder.deliveryAddress4))]),
            ]),
          ]),
        ]),
      ]),
      _c("v-col", { attrs: { cols: "6" } }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Delivery Date:")]),
              _c("td", [
                _c("b", [
                  _vm._v(_vm._s(_vm.formatDate(_vm.salesOrder.orderDate))),
                ]),
              ]),
            ]),
            _c("tr", [
              _c("td", [_vm._v("Version:")]),
              _c("td", [_c("b", [_vm._v(_vm._s(_vm.salesOrder.printCount))])]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }