<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="salesOrders"
      class="elevation-1"
      :loading="isLoading"
      sort-by="deliveryDate"
      sort-desc
    >
      <template v-slot:item.orderDate="{ item }">
        {{ formatDate(item.orderDate) }}
      </template>
      <template v-slot:item.deliveryDate="{ item }">
        {{ formatDate(item.deliveryDate) }}
      </template>
      <template v-slot:item.orderTotalIncVat="{ item }">
        <span v-if="item.orderTotalIncVat">
          R {{ formatCurrency(item.orderTotalIncVat) }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import { mdiDelete, mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListOrder',
  module: 'Orders',
  mixins: [loading],
  data() {
    return {
      mdiDelete,
      mdiPencil,
      salesOrders: [],
      history: [{
        text: 'Home',
        disabled: false,
        href: '/#/members',
        depth: 0,
      }, {
        text: 'Sales Orders',
        disabled: true,
        depth: 0,
      }],
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Sales Order No',
          value: 'salesOrder',
        },
        {
          text: this.$$t('status'),
          value: 'status',
        },
        {
          text: this.$$t('customerName'),
          value: 'customerName',
        },
        {
          text: this.$$t('orderDate'),
          value: 'orderDate',
        },
        {
          text: this.$$t('deliveryDate'),
          value: 'deliveryDate',
        },
        {
          text: this.$$t('version'),
          value: 'printCount',
        },
        {
          text: 'Sales Order Total',
          value: 'SalesOrderValue',
        },
        {
          text: 'Sales Order Lines',
          value: 'salesOrderLineCount',
        },
        {
          text: this.$$t('invoiceNo'),
          value: 'invoice',
        },
        {
          text: this.$$t('invoiceAmount'),
          value: 'invoiceValue',
        },
        {
          text: 'Invoices Lines',
          value: 'invoiceLineCount',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
    this.SET_title('Sales Orders');
  },
  methods: {
    /** Vuex methods
     */
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    async refresh() {
      const start = new Date();
      start.setMonth(start.getMonth() - 1);
      const end = new Date();
      const url = `odata/salesOrders/GetRecon?start=${this.formatDate(start)}&end=${this.formatDate(end)}`;
      const { data } = await this.$http.get(url);
      this.salesOrders = data.value;
    },
  },
};
</script>

<docs>
# Category example

'''js
<Category />
'''
</docs>
