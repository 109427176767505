var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c("h1", [_vm._v("StockItem")]),
          _c("v-select", {
            attrs: {
              "data-cy": "FormStockItem-supplierId",
              filled: "",
              label: _vm.$$t("supplierId"),
              items: _vm.items,
            },
            model: {
              value: _vm.localStockItem.supplierId,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "supplierId", $$v)
              },
              expression: "localStockItem.supplierId",
            },
          }),
          _c("v-text-field", {
            attrs: {
              filled: "",
              "data-cy": "FormStockItem-itemCode",
              label: _vm.$$t("itemCode"),
            },
            model: {
              value: _vm.localStockItem.itemCode,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "itemCode", $$v)
              },
              expression: "localStockItem.itemCode",
            },
          }),
          _c("v-text-field", {
            attrs: {
              filled: "",
              "data-cy": "FormStockItem-description",
              label: _vm.$$t("description"),
            },
            model: {
              value: _vm.localStockItem.description,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "description", $$v)
              },
              expression: "localStockItem.description",
            },
          }),
          _c("v-select", {
            attrs: {
              "data-cy": "FormStockItem-categoryId",
              filled: "",
              label: _vm.$$t("categoryId"),
              items: _vm.items,
            },
            model: {
              value: _vm.localStockItem.categoryId,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "categoryId", $$v)
              },
              expression: "localStockItem.categoryId",
            },
          }),
          _c("v-text-field", {
            attrs: {
              filled: "",
              "data-cy": "FormStockItem-barcode",
              label: _vm.$$t("barcode"),
            },
            model: {
              value: _vm.localStockItem.barcode,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "barcode", $$v)
              },
              expression: "localStockItem.barcode",
            },
          }),
          _c("ImageUpload", {
            attrs: {
              "data-cy": "FormStockItem-photo-image-upload",
              label: _vm.$$t("photo"),
            },
            model: {
              value: _vm.localStockItem.photo,
              callback: function ($$v) {
                _vm.$set(_vm.localStockItem, "photo", $$v)
              },
              expression: "localStockItem.photo",
            },
          }),
          _vm.localStockItem.photo
            ? _c("v-img", {
                attrs: {
                  "data-cy": "FormStockItem-photo-image-display",
                  src: _vm.localStockItem.photo,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormStockItem-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.$parent.isSaving,
                    "data-cy": "FormStockItem-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }