var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.stockItems,
      loading: _vm.isLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "item.photo",
        fn: function ({ item }) {
          return [
            _c("v-img", {
              attrs: { contain: "", height: "64", src: item.photo },
            }),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  icon: "",
                  color: "secondary",
                  to: {
                    name: "shop-EditStockItem",
                    query: { id: item.id },
                  },
                },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: {
                      small: "",
                      "data-cy": `button-StockItem-edit-${item.id}`,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.mdiPencil) + " ")]
                ),
              ],
              1
            ),
            _c(
              "v-icon",
              {
                attrs: {
                  small: "",
                  color: "error",
                  "data-cy": `button-StockItem-remove-${item.id}`,
                },
                on: {
                  click: function ($event) {
                    return _vm.remove(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.mdiDelete) + " ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }