var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center w-100 h-100",
      attrs: { "data-cy": "stock-items-loading" },
    },
    [_c("v-progress-linear", { attrs: { value: _vm.progressValue } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }