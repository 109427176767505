var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("StockItemsLoading") : _vm._e(),
      !_vm.isLoading && !_vm.$vuetify.breakpoint.mobile
        ? _c("CardListFavouriteItems", {
            attrs: {
              isLoading: _vm.isLoading,
              stockItems: _vm.availableStockItems,
            },
            on: { change: _vm.update, refresh: _vm.refresh },
          })
        : !_vm.isLoading && _vm.$vuetify.breakpoint.mobile
        ? _c("MobileListFavouriteItems", {
            attrs: { stockItems: _vm.availableStockItems },
            on: { change: _vm.update, refresh: _vm.refresh },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }