var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { small: "" } }, "v-btn", attrs, false),
                  on
                ),
                [_vm._v(" Edit ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                "Edit Customer: " + _vm._s(_vm.localCustomer.customerDesc)
              ),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: { disabled: "", label: "Customer Code" },
                            model: {
                              value: _vm.localCustomer.customerCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.localCustomer, "customerCode", $$v)
                              },
                              expression: "localCustomer.customerCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              label: "Customer Description",
                            },
                            model: {
                              value: _vm.localCustomer.customerDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.localCustomer, "customerDesc", $$v)
                              },
                              expression: "localCustomer.customerDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Hide Stock on Hand" },
                            model: {
                              value: _vm.localCustomer.hideStockOnHand,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localCustomer,
                                  "hideStockOnHand",
                                  $$v
                                )
                              },
                              expression: "localCustomer.hideStockOnHand",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }