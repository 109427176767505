import { render, staticRenderFns } from "./ListSupplier.vue?vue&type=template&id=0ca1759e"
import script from "./ListSupplier.vue?vue&type=script&lang=js"
export * from "./ListSupplier.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ListSupplier.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ca1759e')) {
      api.createRecord('0ca1759e', component.options)
    } else {
      api.reload('0ca1759e', component.options)
    }
    module.hot.accept("./ListSupplier.vue?vue&type=template&id=0ca1759e", function () {
      api.rerender('0ca1759e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/shop/src/views/ListSupplier.vue"
export default component.exports