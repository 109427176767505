var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "grey--text",
      attrs: {
        elevation: "0",
        title: "Cart",
        "data-cy": "btn-mini-cart",
        height: "48",
        color: "accent",
        to: { name: "shop-Cart" },
      },
    },
    [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("@/assets/icon-cart.svg") },
      }),
      _vm.total_items > 0
        ? _c("span", { staticClass: "black--text" }, [
            _vm._v(" R " + _vm._s(_vm.formatCurrency(_vm.total_amount)) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }