var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _vm.isLoading ? _c("ProgressLinearTimeout") : _vm._e(),
      _c(
        "v-row",
        { staticClass: "nav-list" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column h-100",
              attrs: { cols: "12", lg: "8" },
            },
            [
              _c(
                "v-card",
                { staticClass: "h-100 mb-10" },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Soft Sales Activity "),
                  ]),
                  _c("v-card-text", [_c("SoftSalesActivity")], 1),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "h-100" },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Soft Sales "),
                  ]),
                  _c("v-card-text", [_c("SoftSales")], 1),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column h-100",
              attrs: { cols: "12", lg: "4" },
            },
            [
              _c(
                "v-card",
                { staticClass: "h-100 mb-10" },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Customer Ratings "),
                  ]),
                  _c("v-card-text", [_c("CustomerRatings")], 1),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "h-100" },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Push Notfications "),
                  ]),
                  _c("v-card-text", [_c("SendPushNotifications")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-10 h-100" },
                [
                  _c("v-card-title", { attrs: { "primary-title": "" } }, [
                    _vm._v(" Slow Customers Report "),
                  ]),
                  _c("v-card-text", [_c("ReportSlowCustomers")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }