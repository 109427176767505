var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stockItem
    ? _c(
        "v-dialog",
        {
          staticStyle: { "align-self": "end" },
          attrs: { "max-width": "500" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              block: "",
                              height: _vm.small ? 30 : 40,
                              small: _vm.small,
                              color: "primary",
                              "data-cy": "BuyInStock-button-open-dialog",
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_vm._v(" " + _vm._s(_vm.text) + " ")]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2525150426
          ),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("Enter Amount for " + _vm._s(_vm.stockItem.description)),
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      "data-cy": "BuyInStock-quantity",
                      label: "Quantity",
                    },
                    model: {
                      value: _vm.quantity,
                      callback: function ($$v) {
                        _vm.quantity = $$v
                      },
                      expression: "quantity",
                    },
                  }),
                  _c("p", [
                    _vm._v(
                      "Our telesales department will be in contact with the expected available date"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "BuyInStock-button-cancel",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "data-cy": "BuyInStock-button-ok",
                        color: "success",
                        loading: _vm.isSaving,
                      },
                      on: { click: _vm.buyIn },
                    },
                    [_vm._v(" OK ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }