var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "grey--text",
      attrs: {
        text: "",
        title: "Cart",
        "data-cy": "btn-mini-cart",
        to: { name: "shop-Cart" },
      },
    },
    [
      _c(
        "v-badge",
        {
          attrs: {
            color: "primary",
            left: "",
            transition: "slide-x-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "badge",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.total_items) + " ")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { size: "26" } }, [
            _vm._v(" " + _vm._s(_vm.mdiCartOutline) + " "),
          ]),
          _vm._v(" R " + _vm._s(_vm.formatCurrency(_vm.total_amount)) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }