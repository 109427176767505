var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.salesOrders,
          loading: _vm.isLoading,
          "sort-by": "deliveryDate",
          "sort-desc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.orderDate",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.orderDate)) + " "),
              ]
            },
          },
          {
            key: "item.deliveryDate",
            fn: function ({ item }) {
              return [
                _vm._v(" " + _vm._s(_vm.formatDate(item.deliveryDate)) + " "),
              ]
            },
          },
          {
            key: "item.orderTotalIncVat",
            fn: function ({ item }) {
              return [
                item.orderTotalIncVat
                  ? _c("span", [
                      _vm._v(
                        " R " +
                          _vm._s(_vm.formatCurrency(item.orderTotalIncVat)) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v(" - ")]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }