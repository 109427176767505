var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-select", {
        ref: "select",
        attrs: {
          items: _vm.items,
          "item-text": "delAddress01",
          "return-object": "",
          label: "Delivery Address",
          filled: "",
          rules: _vm.rules,
          "data-cy": "deliveryAddress",
          "background-color": "white",
          solo: _vm.solo,
          outlined: _vm.outlined,
        },
        on: { change: _vm.change },
        scopedSlots: _vm._u([
          {
            key: "prepend-item",
            fn: function () {
              return [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: "Search" },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-2" }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "min-height": "50px",
                      "margin-bottom": "15px",
                      width: "100%",
                    },
                  },
                  [
                    _c("p", [_vm._v(_vm._s(item.delAddress01))]),
                    _c("p", [_vm._v(_vm._s(item.delAddress02))]),
                    _c("p", [_vm._v(_vm._s(item.delAddress03))]),
                    _c("v-divider"),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.deliveryAddress,
          callback: function ($$v) {
            _vm.deliveryAddress = $$v
          },
          expression: "deliveryAddress",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }