var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-licensePlate",
                  label: _vm.$$t("licensePlate"),
                },
                model: {
                  value: _vm.localVehicle.licensePlate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "licensePlate", $$v)
                  },
                  expression: "localVehicle.licensePlate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  filled: "",
                  items: _vm.makes,
                  "data-cy": "FormVehicle-make",
                  label: _vm.$$t("make"),
                },
                model: {
                  value: _vm.localVehicle.make,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "make", $$v)
                  },
                  expression: "localVehicle.make",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-model",
                  label: _vm.$$t("model"),
                },
                model: {
                  value: _vm.localVehicle.model,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "model", $$v)
                  },
                  expression: "localVehicle.model",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.fuelTypes,
                  filled: "",
                  "data-cy": "FormVehicle-fuelType",
                  label: _vm.$$t("fuelType"),
                },
                model: {
                  value: _vm.localVehicle.fuelType,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "fuelType", $$v)
                  },
                  expression: "localVehicle.fuelType",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-licenseRenewalDate",
                  label: _vm.$$t("licenseRenewalDate"),
                },
                model: {
                  value: _vm.localVehicle.licenseRenewalDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "licenseRenewalDate", $$v)
                  },
                  expression: "localVehicle.licenseRenewalDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-nextServiceDate",
                  label: _vm.$$t("nextServiceDate"),
                },
                model: {
                  value: _vm.localVehicle.nextServiceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "nextServiceDate", $$v)
                  },
                  expression: "localVehicle.nextServiceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-nextTireChangeDate",
                  label: _vm.$$t("nextTireChangeDate"),
                },
                model: {
                  value: _vm.localVehicle.nextTireChangeDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "nextTireChangeDate", $$v)
                  },
                  expression: "localVehicle.nextTireChangeDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-coldStorageCertificateRenewalDate",
                  label: _vm.$$t("coldStorageCertificateRenewalDate"),
                },
                model: {
                  value: _vm.localVehicle.coldStorageCertificateRenewalDate,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.localVehicle,
                      "coldStorageCertificateRenewalDate",
                      $$v
                    )
                  },
                  expression: "localVehicle.coldStorageCertificateRenewalDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormVehicle-refrigerationServiceDate",
                  label: _vm.$$t("refrigerationServiceDate"),
                },
                model: {
                  value: _vm.localVehicle.refrigerationServiceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "refrigerationServiceDate", $$v)
                  },
                  expression: "localVehicle.refrigerationServiceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("FileUpload", {
                attrs: {
                  "data-cy": "FormStockItem-coldStorageCertificate",
                  label: _vm.$$t("coldStorageCertificate"),
                },
                model: {
                  value: _vm.localVehicle.coldStorageCertificate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localVehicle, "coldStorageCertificate", $$v)
                  },
                  expression: "localVehicle.coldStorageCertificate",
                },
              }),
              _vm.localVehicle.coldStorageCertificate
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        small: "",
                        href: _vm.localVehicle.coldStorageCertificate,
                      },
                    },
                    [_vm._v(" Download Certificate ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4 btn-cancel",
              attrs: {
                color: "default",
                outlined: "",
                "data-cy": "FormVehicle-button-cancel",
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "primary",
                type: "submit",
                loading: _vm.isSaving,
                "data-cy": "FormVehicle-button-save",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }