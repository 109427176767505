/* eslint-disable max-len */
/* eslint-disable global-require */
export default {
  data() {
    return {
      menuItems: [{
        name: 'menu.home',
        icon: require('@/assets/icon-home-white.svg'),
        pathName: 'shop-ShopHome',
        iconSelected: require('@/assets/icon-home-selected.svg'),
      },
      {
        name: 'menu.shop',
        pathName: 'shop-ShopCategories',
        icon: require('@/assets/icon-cart-white.svg'),
        iconSelected: require('@/assets/icon-cart-selected.svg'),
      },
      {
        name: 'menu.favourites',
        pathName: 'shop-Favourites',
        icon: require('@/assets/icon-favourite-white.svg'),
        iconSelected: require('@/assets/icon-favourites-selected.svg'),
      },
      {
        name: 'menu.quotes',
        pathName: 'shop-ListQuotes',
        icon: require('@/assets/icon-quotes.svg'),
        iconSelected: require('@/assets/icon-quotes-selected.svg'),
      },
      {
        name: 'menu.accounts',
        pathName: 'finance-FinanceHome',
        icon: require('@/assets/icon-accounts-white.svg'),
        iconSelected: require('@/assets/icon-accounts-active.svg'),
      },
      {
        name: 'menu.divisions',
        pathName: 'shop-ListDivision',
        icon: require('@/assets/icon-division.svg'),
        iconSelected: require('@/assets/icon-division-selected.svg'),
      },
      {
        name: 'menu.customerCare',
        pathName: 'shop-CustomerCare',
        icon: require('@/assets/icon-customer-care-white.svg'),
        iconSelected: require('@/assets/icon-customer-care-active.svg'),
      },
      {
        name: 'menu.productRequest',
        pathName: 'shop-ProductRequest',
        icon: require('@/assets/icon-product-request.svg'),
        iconSelected: require('@/assets/icon-product-request-selected.svg'),
      },
      ],
    };
  },
};
