var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.isLoading,
            transition: "expand-transition",
            type: "article",
          },
        },
        [
          _c("FormCostCentre", {
            attrs: { CostCentre: _vm.editCostCentre, isSaving: _vm.isSaving },
            on: {
              save: _vm.save,
              cancel: function ($event) {
                return _vm.$router.push({
                  name: "shop-ListCostCentre",
                  query: { customerCode: _vm.$route.query.customerCode },
                })
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }