var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3", xl: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormDriver-name",
                  label: _vm.$$t("name"),
                },
                model: {
                  value: _vm.localDriver.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "name", $$v)
                  },
                  expression: "localDriver.name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3", xl: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  "data-cy": "FormDriver-idNumber",
                  label: _vm.$$t("idNumber"),
                },
                model: {
                  value: _vm.localDriver.idNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "idNumber", $$v)
                  },
                  expression: "localDriver.idNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3", xl: "3" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormDriver-licenseExpirationDate",
                  label: _vm.$$t("licenseExpirationDate"),
                },
                model: {
                  value: _vm.localDriver.licenseExpirationDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "licenseExpirationDate", $$v)
                  },
                  expression: "localDriver.licenseExpirationDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4", lg: "3", xl: "3" } },
            [
              _c("DatePicker", {
                attrs: {
                  filled: "",
                  "data-cy": "FormDriver-pdpLicenseExpiry",
                  label: _vm.$$t("pdpLicenseExpiry"),
                },
                model: {
                  value: _vm.localDriver.pdpLicenseExpiry,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "pdpLicenseExpiry", $$v)
                  },
                  expression: "localDriver.pdpLicenseExpiry",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
            [
              _vm.localDriver.licensePhoto
                ? _c("v-img", {
                    staticClass: "mb-5",
                    attrs: {
                      height: "300",
                      contain: "",
                      src: _vm.localDriver.licensePhoto,
                    },
                  })
                : _vm._e(),
              _c("ImageUpload", {
                attrs: {
                  "data-cy": "FormStockItem-licensePhoto",
                  label: _vm.$$t("licensePhoto"),
                },
                model: {
                  value: _vm.localDriver.licensePhoto,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "licensePhoto", $$v)
                  },
                  expression: "localDriver.licensePhoto",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
            [
              _vm.localDriver.driverPhoto
                ? _c("v-img", {
                    staticClass: "mb-5",
                    attrs: {
                      height: "300",
                      contain: "",
                      src: _vm.localDriver.driverPhoto,
                    },
                  })
                : _vm._e(),
              _c("ImageUpload", {
                attrs: {
                  "data-cy": "FormStockItem-driverPhoto",
                  label: _vm.$$t("driverPhoto"),
                },
                model: {
                  value: _vm.localDriver.driverPhoto,
                  callback: function ($$v) {
                    _vm.$set(_vm.localDriver, "driverPhoto", $$v)
                  },
                  expression: "localDriver.driverPhoto",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-end mb-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4 btn-cancel",
              attrs: {
                color: "default",
                outlined: "",
                "data-cy": "FormDriver-button-cancel",
              },
              on: { click: _vm.cancel },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "primary",
                type: "submit",
                loading: _vm.isSaving,
                "data-cy": "FormDriver-button-save",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }