var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "h-100",
    attrs: {
      items: _vm.items,
      "item-key": "customerAccount",
      headers: _vm.headers,
      "sort-by": "created",
      "sort-desc": true,
      search: _vm.search,
      loading: _vm.isLoading,
      "items-per-page": 25,
      "footer-props": { "items-per-page-options": [15, 25, 50, 100, 500, -1] },
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-end",
                    attrs: { offset: "6", cols: "6" },
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        "append-icon": _vm.mdiMagnify,
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c("ExportToExcelButton", {
                      attrs: { name: "Customers", data: _vm.exportItems },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", "max-width": "600" },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-title", [
                      _vm._v("Copy and paste the link to the customer"),
                    ]),
                    _vm.item
                      ? _c(
                          "v-card-text",
                          [
                            _c("v-text-field", {
                              attrs: {
                                value: _vm.url,
                                "single-line": "",
                                "hide-details": "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            nativeOn: {
                              click: function ($event) {
                                _vm.dialog = false
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.created",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDate(item.created)) + " ")]
        },
      },
      {
        key: "item.emailAddress",
        fn: function ({ item }) {
          return [
            _c("a", { attrs: { href: "mailto:" + item.emailAddress } }, [
              _vm._v(_vm._s(item.emailAddress)),
            ]),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c(
              "v-btn",
              {
                attrs: { color: "primary", dark: "", small: "" },
                on: {
                  click: function ($event) {
                    return _vm.showLink(item)
                  },
                },
              },
              [_vm._v("Link")]
            ),
          ]
        },
      },
      {
        key: "item.edit",
        fn: function ({ item }) {
          return [
            _c("CustomerEditDialog", {
              attrs: { customer: item },
              on: { save: _vm.saveCustomer },
            }),
          ]
        },
      },
      {
        key: "item.costCentre",
        fn: function ({ item }) {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  color: "default",
                  to: {
                    name: "shop-ListCostCentre",
                    query: { customerCode: item.customerCode },
                  },
                  loading: _vm.isSaving,
                },
              },
              [_vm._v(" Cost Centres ")]
            ),
          ]
        },
      },
      {
        key: "item.emails",
        fn: function ({ item }) {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  color: "default",
                  to: {
                    name: "shop-ListEmail",
                    query: { customerCode: item.customerCode },
                  },
                  loading: _vm.isSaving,
                },
              },
              [_vm._v(" Emails ")]
            ),
          ]
        },
      },
      {
        key: "item.customerCode",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("span", [_vm._v(_vm._s(item.customerCode))]),
                item.hideStockOnHand
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "ml-2",
                        attrs: { title: "Stock on Hand not visible" },
                      },
                      [_vm._v(" " + _vm._s(_vm.mdiPackageVariantRemove) + " ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }