var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: _vm.$vuetify.breakpoint.mobile,
        persistent: "",
        "max-width": _vm.$vuetify.breakpoint.mobile ? undefined : 415,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        {
          class: {
            "d-flex flex-column justify-center": _vm.$vuetify.breakpoint.mobile,
          },
          staticStyle: { padding: "20px" },
          attrs: { color: "primary", rounded: "0", centered: "" },
        },
        [
          _c("v-card-title", { staticClass: "white--text text-center" }, [
            _vm._v(" Here's a product you might like! "),
          ]),
          _c(
            "v-card-text",
            { staticClass: "d-flex flex-column align-center" },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "v-row",
                {
                  key: index,
                  staticClass: "text-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "stock-item-card", attrs: { cols: "12" } },
                    [
                      _c("StockItemCard", {
                        attrs: { stockItem: item },
                        on: {
                          added: function ($event) {
                            return _vm.added(item)
                          },
                          favouriteChange: function ($event) {
                            return _vm.addToFavourites(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "accent--text mb-5 text-decoration-underline",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.remove(item)
                            },
                          },
                        },
                        [_vm._v(" Maybe later ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }