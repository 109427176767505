var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalItems > 0
    ? _c(
        "v-badge",
        {
          attrs: {
            color: "primary",
            left: "",
            transition: "slide-x-transition",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "badge",
                fn: function () {
                  return [_vm._v(" " + _vm._s(_vm.totalItems) + " ")]
                },
                proxy: true,
              },
            ],
            null,
            false,
            3363469777
          ),
        },
        [
          _c("v-icon", { staticClass: "mr-1", attrs: { size: "26" } }, [
            _vm._v(" " + _vm._s(_vm.mdiArchiveClockOutline) + " "),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }