var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      loading: _vm.isLoading,
      headers: _vm.headers,
      items: _vm.productImages,
      elevation: 0,
      search: _vm.search,
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-end",
                    attrs: { offset: "6", cols: "6" },
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c("ExportToExcelButton", {
                      attrs: { data: _vm.excelData, name: "Products Images" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.image",
        fn: function ({ item }) {
          return [
            _c("v-img", {
              attrs: { "max-width": "300", contain: "", src: item.image },
            }),
          ]
        },
      },
      {
        key: "item.imageSize",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatNumber(item.imageSize)) + " ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }