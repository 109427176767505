var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        bottom: !_vm.$vuetify.breakpoint.mobile,
        "content-class": _vm.$vuetify.breakpoint.mobile ? "search-menu" : "",
        absolute: _vm.$vuetify.breakpoint.mobile,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    ref: "searchBox",
                    staticClass: "search-box",
                    attrs: {
                      loading: _vm.isLoading,
                      "data-cy": "input-search",
                      clearable: !_vm.$vuetify.breakpoint.mobile,
                      label:
                        _vm.searchType === "All"
                          ? "Search Gourmet Foods"
                          : "Search Favourites",
                      solo: "",
                      flat: "",
                      "hide-details": "",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.menu = false
                      },
                      focus: function ($event) {
                        _vm.menu = true
                      },
                      input: function ($event) {
                        _vm.menu = true
                      },
                      change: function ($event) {
                        _vm.menu = true
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onEnter.apply(null, arguments)
                      },
                      "click:clear": _vm.clearSearch,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      "data-cy":
                                                        "toggle-search-type",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm.search?.length > 0
                                                  ? _c("v-img", {
                                                      attrs: {
                                                        height: "18",
                                                        width: "18",
                                                        contain: "",
                                                        src: require("../../../../assets/icon-search.svg"),
                                                      },
                                                      on: {
                                                        click: _vm.searchText,
                                                      },
                                                    })
                                                  : _vm.searchType === "All"
                                                  ? _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.mdiFeatureSearch
                                                        )
                                                      ),
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(_vm.mdiHeart)
                                                      ),
                                                    ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            "data-cy": "toggle-search-type-all",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.searchType = "All"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.mdiFeatureSearch
                                                      )
                                                    ),
                                                  ]),
                                                  _c("strong", [
                                                    _vm._v("All Products"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            "data-cy":
                                              "toggle-search-type-favourites",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.searchType =
                                                        "Favourites"
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.mdiHeart)
                                                      ),
                                                    ]
                                                  ),
                                                  _c("strong", [
                                                    _vm._v("Favourites"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  },
                  on
                )
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm.availableItems.length > 0
        ? _c(
            "v-list",
            [
              _c("v-virtual-scroll", {
                attrs: {
                  items: _vm.availableItems,
                  "item-height": "64",
                  height: "300",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ item, index }) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              attrs: { "data-cy": `search-result-${index}` },
                              on: {
                                click: function ($event) {
                                  return _vm.selectItem(item)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  item.photo
                                    ? _c("v-img", {
                                        attrs: {
                                          height: "55",
                                          width: "55",
                                          contain: "",
                                          src: item.photo,
                                          "lazy-src": item.photo,
                                        },
                                      })
                                    : _c("v-img", {
                                        attrs: {
                                          contain: "",
                                          stretch: "",
                                          src: require("../assets/default-product-image.png"),
                                          "lazy-src": require("../assets/default-product-image.png"),
                                          height: "55",
                                          width: "55",
                                        },
                                      }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(" " + _vm._s(item.text) + " "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3987970783
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }