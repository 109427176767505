var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    staticClass: "mb-4",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function () {
            return [
              _c("thead", [
                _c("tr", [
                  _c("th"),
                  _c("th", [_c("h3", [_vm._v("Product")])]),
                  _c("th", [_c("h3", [_vm._v("Stock")])]),
                  _c("th", [_c("h3", [_vm._v("Quantity")])]),
                  _c("th", [_c("h3", [_vm._v("Price")])]),
                  _c("th", [_c("h3", [_vm._v("VAT")])]),
                  _c("th", [_c("h3", [_vm._v("Total")])]),
                  _c("th"),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _vm._l(_vm.cartItems, function (cart, index) {
                    return _c(
                      "tr",
                      {
                        key: cart.id,
                        attrs: { "data-cy": `table-cart-row-${index}` },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("v-img", {
                              attrs: {
                                width: "92",
                                contain: "",
                                src: _vm.resizeImage(cart.stockItem),
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("h3", [
                            _vm._v(_vm._s(cart.stockItem.description)),
                          ]),
                        ]),
                        _c("td", { staticClass: "price-col" }, [
                          cart.stockItem.quantity > 0
                            ? _c("h4", [
                                _vm._v(_vm._s(cart.stockItem.quantity)),
                              ])
                            : _c("h4", { staticClass: "red--text" }, [
                                _vm._v("Out of stock"),
                              ]),
                        ]),
                        _c(
                          "td",
                          { staticClass: "qty-col" },
                          [
                            !_vm.disabled
                              ? _c("v-text-field", {
                                  staticClass: "quantity-input",
                                  attrs: {
                                    "data-cy": "quantity-input",
                                    rules: [_vm.rules.non_zero],
                                    "append-icon": _vm.mdiPlus,
                                    "prepend-inner-icon": _vm.getDecreaseIcon(
                                      cart.amount
                                    ),
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("change", {
                                        itemCode: cart.stockItem.itemCode,
                                        amount: $event,
                                        id: cart.id,
                                      })
                                    },
                                    "click:append": function ($event) {
                                      return _vm.$emit("change", {
                                        itemCode: cart.stockItem.itemCode,
                                        amount: cart.amount + 1,
                                        id: cart.id,
                                      })
                                    },
                                    "click:prepend-inner": function ($event) {
                                      return _vm.$emit("change", {
                                        itemCode: cart.stockItem.itemCode,
                                        amount: cart.amount - 1,
                                        id: cart.id,
                                      })
                                    },
                                  },
                                  model: {
                                    value: cart.amount,
                                    callback: function ($$v) {
                                      _vm.$set(cart, "amount", $$v)
                                    },
                                    expression: "cart.amount",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(" " + _vm._s(cart.amount) + " "),
                                ]),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "price-col" }, [
                          _c("h4", [
                            _vm._v(
                              "R " + _vm._s(_vm.formatCurrency(cart.price))
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "price-col" }, [
                          _c("h4", [
                            _vm._v("R " + _vm._s(_vm.formatCurrency(cart.tax))),
                          ]),
                        ]),
                        _c("td", { staticClass: "price-col" }, [
                          _c("h4", [
                            _vm._v(
                              "R " +
                                _vm._s(_vm.formatCurrency(cart.total)) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "td",
                          [
                            !_vm.disabled
                              ? _c(
                                  "v-btn",
                                  {
                                    staticStyle: { float: "right" },
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("remove", cart)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "error" } },
                                      [_vm._v(_vm._s(_vm.mdiTrashCan))]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm.cart.length > 0
                    ? _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "8" } },
                          [_vm._t("notes")],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm.cart.length > 0
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "small-td text-right",
                            attrs: { colspan: "8" },
                          },
                          [
                            _c("h2", { staticClass: "mt-9" }, [
                              _c("span", [_vm._v("Total:")]),
                              _vm._v(
                                " R " +
                                  _vm._s(_vm.formatCurrency(_vm.total_amount)) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.cart.length > 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "7" } }),
                        _c("td", [_vm._t("costCentre")], 2),
                      ])
                    : _vm._e(),
                  _vm.cartItems.length > 0
                    ? _c("tr", [
                        _c(
                          "td",
                          { attrs: { colspan: "7" } },
                          [_vm._t("buttons-left")],
                          2
                        ),
                        _c("td", [_vm._t("buttons")], 2),
                      ])
                    : _vm._e(),
                  _vm.cartItems.length > 0 && _vm.client
                    ? _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "small-td text-right",
                            attrs: { colspan: "8" },
                          },
                          [
                            _c("h2", { staticClass: "mt-9" }, [
                              _c("span", [_vm._v("Current Balance:")]),
                              _vm._v(
                                " R " +
                                  _vm._s(
                                    _vm.formatCurrency(_vm.client.dcBalance)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.cartItems.length > 0 && _vm.client
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c("h2", { staticClass: "mt-9" }, [
                            _vm._v(" " + _vm._s(_vm.client.cDescription) + " "),
                          ]),
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "small-td text-right",
                            attrs: { colspan: "5" },
                          },
                          [
                            _c("h2", { staticClass: "mt-9" }, [
                              _c("span", [_vm._v("Balance after Order:")]),
                              _vm._v(
                                " R " +
                                  _vm._s(_vm.formatCurrency(_vm.remaining)) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }