import { render, staticRenderFns } from "./CartItemCard.vue?vue&type=template&id=535d1bce"
import script from "./CartItemCard.vue?vue&type=script&lang=js"
export * from "./CartItemCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('535d1bce')) {
      api.createRecord('535d1bce', component.options)
    } else {
      api.reload('535d1bce', component.options)
    }
    module.hot.accept("./CartItemCard.vue?vue&type=template&id=535d1bce", function () {
      api.rerender('535d1bce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/shop/src/components/CartItemCard.vue"
export default component.exports