var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" Deliveries "),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v(" Schedule ")]),
                  _c("v-tab", [_vm._v(" History ")]),
                  _c("v-tab", [_vm._v(" Delivery Dates ")]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            to: {
                              name: "shop-EditDelivery",
                            },
                          },
                        },
                        [_vm._v(" Schedule Delivery ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-tab-item"),
                  _c("v-tab-item", [_c("ListDeliveryDate")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }