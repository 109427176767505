var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Breadcrumbs", { attrs: { history: _vm.history } }),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.costCentres,
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "success",
                          "data-cy": "CostCentre-button-new",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "shop-EditCostCentre",
                              query: {
                                customerCode: _vm.$route.query.customerCode,
                              },
                            })
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.buttons.new")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item.requiresApproval",
            fn: function ({ item }) {
              return [
                _c("v-checkbox", {
                  attrs: {
                    disabled: "",
                    "data-cy": "FormCostCentre-requiresApproval",
                  },
                  model: {
                    value: item.requiresApproval,
                    callback: function ($$v) {
                      _vm.$set(item, "requiresApproval", $$v)
                    },
                    expression: "item.requiresApproval",
                  },
                }),
              ]
            },
          },
          {
            key: "item.approvers",
            fn: function ({ item }) {
              return [
                _c("display-users", {
                  model: {
                    value: item.approvers,
                    callback: function ($$v) {
                      _vm.$set(item, "approvers", $$v)
                    },
                    expression: "item.approvers",
                  },
                }),
              ]
            },
          },
          {
            key: "item.buyers",
            fn: function ({ item }) {
              return [
                _c("display-users", {
                  model: {
                    value: item.buyers,
                    callback: function ($$v) {
                      _vm.$set(item, "buyers", $$v)
                    },
                    expression: "item.buyers",
                  },
                }),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c("TableBasicButtons", {
                  attrs: {
                    editLink: {
                      name: "shop-EditCostCentre",
                      query: {
                        id: item.id,
                        customerCode: _vm.$route.query.customerCode,
                      },
                    },
                  },
                  on: {
                    delete: function ($event) {
                      return _vm.remove(item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }