var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.divisions,
                  loading: _vm.isLoading,
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary--text",
                                attrs: {
                                  color: "#EE1C25",
                                  "data-cy": "Division-button-new",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "shop-EditDivision",
                                    })
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "mr-2" }, [
                                  _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.buttons.new")) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "text-h5 ml-2" }, [
                              _vm._v("Departments"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c("TableBasicButtons", {
                          attrs: {
                            editLink: {
                              name: "shop-EditDivision",
                              query: { id: item.id },
                            },
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.remove(item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }