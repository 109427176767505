<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mobile" cols="12" class="w-100">
        <div class="d-flex align-stretch background--grey w-100 pa-2">
          <div class="w-100 mr-2 pa-2" :class="{ 'background--white': selected === 'Invoices' }"
            @click="selected = 'Invoices'"
          >
            Invoices
          </div>
          <div class="w-100 mr-2 pa-2"
            :class="{ 'background--white': selected === 'Credit Notes' }"
            @click="selected = 'Credit Notes'"
          >
            Credit Notes
          </div>
        </div>
      </v-col>
      <v-col v-else cols="12">
        <v-btn :color="selected === 'Invoices' ? 'primary' : ''"
          @click="selected = 'Invoices'"
          class="mr-5"
        >
          Invoices
        </v-btn>
        <v-btn :color="selected === 'Credit Notes' ? 'primary' : ''"
          @click="selected = 'Credit Notes'"
        >
          Credit Notes
        </v-btn>
      </v-col>
      <v-col cols="12">
        <ListFinanceDocuments v-if="selected === 'Invoices'" arType="OInv" valueProp="debit" />
        <ListFinanceDocuments v-if="selected === 'Credit Notes'" arType="Crn" valueProp="credit" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import ListFinanceDocuments from '../components/ListFinanceDocuments.vue';

export default {
  name: 'FinanceHome',
  components: { ListFinanceDocuments },
  data() {
    return {
      selected: 'Invoices',
    };
  },
  async mounted() {
    this.SET_title('Accounts');
    this.SET_expandedSearch(false);
  },
  methods: {
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
  },
};
</script>
