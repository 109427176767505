var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.softSaleStockItems,
      loading: _vm.isLoading,
      search: _vm.search,
      "sort-by": "created",
      "sort-desc": "",
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          to: { name: "shop-EditSoftSaleStockItem" },
                        },
                      },
                      [_vm._v(" New Soft Sale ")]
                    ),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": _vm.mdiMagnify,
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.created",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDateTime(item.created)) + " ")]
        },
      },
      {
        key: "item.photo",
        fn: function ({ item }) {
          return [
            _c("v-img", {
              attrs: { src: item.photo, contain: "", height: "128" },
            }),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c(
              "v-icon",
              {
                attrs: {
                  small: "",
                  color: "error",
                  "data-cy": `button-ListSoftSale-remove-${item.id}`,
                },
                on: {
                  click: function ($event) {
                    return _vm.remove(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.mdiDelete) + " ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }