var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list",
        _vm._l(_vm.customerServiceRatings, function (rating, index) {
          return _c(
            "v-list-item",
            { key: index },
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v("Customer Code: " + _vm._s(rating.customerCode)),
                  ]),
                  _c(
                    "v-list-item-subtitle",
                    [
                      _c("v-rating", {
                        attrs: { value: rating.rating, disabled: true },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.mdiClock)),
                              ]),
                              _vm._v(
                                " Created: " +
                                  _vm._s(_vm.formatFromNow(rating.created)) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "primary", small: "" } },
                                [_vm._v(_vm._s(_vm.mdiAccount))]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: { href: `mailto:${rating.createdBy}` },
                                },
                                [_vm._v(" " + _vm._s(rating.createdBy) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }