var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex-container",
      attrs: { "data-cy": "quantity-button-cart" },
    },
    [
      _vm.localValue === 1
        ? _c(
            "button",
            {
              staticClass: "button-blocks button-blocks-left",
              attrs: { type: "button", "data-cy": "quantity-remove" },
              on: {
                click: function ($event) {
                  return _vm.remove()
                },
              },
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiTrashCan))])],
            1
          )
        : _c(
            "button",
            {
              staticClass: "button-blocks button-blocks-left",
              attrs: {
                "data-cy": "quantity-down",
                disabled: _vm.localValue === 0,
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.countDown()
                },
              },
            },
            [_c("span", { staticClass: "text-sizing" }, [_vm._v("-")])]
          ),
      _c("div", { staticClass: "quantity-block" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.localValue,
              expression: "localValue",
              modifiers: { number: true },
            },
          ],
          ref: "quantityInput",
          attrs: { type: "number", "data-cy": "quantity-input" },
          domProps: { value: _vm.localValue },
          on: {
            focus: _vm.quantityClicked,
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.localValue = _vm._n($event.target.value)
              },
              _vm.quantityChanged,
            ],
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.$emit("add")
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _c(
        "button",
        {
          staticClass: "button-blocks button-blocks-right",
          attrs: { "data-cy": "quantity-up", type: "button" },
          on: {
            click: function ($event) {
              return _vm.countUp()
            },
          },
        },
        [_c("span", { staticClass: "text-sizing" }, [_vm._v("+")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }