var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height d-flex flex-column top-inset-padding",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "fill-height d-flex flex-column align-stretch",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "pl-5 pr-5" },
                    [
                      _c("v-img", {
                        staticClass: "slide-from-top mb-5 pa-10",
                        staticStyle: {
                          "max-height": "15vh",
                          "max-width": "100%",
                        },
                        attrs: {
                          src: require("../assets/Logo-Wide.svg"),
                          contain: "",
                          alt: "logo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-main",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [_c("router-view")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("NetworkService"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }