var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: "",
                        disabled: _vm.localCustomerLocation.id !== undefined,
                      },
                      model: {
                        value: _vm.localCustomerLocation.customerType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localCustomerLocation,
                            "customerType",
                            $$v
                          )
                        },
                        expression: "localCustomerLocation.customerType",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: { value: "new", label: "New Customer" },
                      }),
                      _c("v-radio", {
                        attrs: {
                          value: "existing",
                          label: "Existing Customer",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.localCustomerLocation.customerType === "existing"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "data-cy": "FormCustomerLocation-customerCode",
                          label: "Select a Customer",
                          "item-text": "customerDesc",
                          "item-value": "customerCode",
                          items: _vm.customers,
                          disabled: _vm.localCustomerLocation.id !== undefined,
                        },
                        model: {
                          value: _vm.localCustomerLocation.customerCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.localCustomerLocation,
                              "customerCode",
                              $$v
                            )
                          },
                          expression: "localCustomerLocation.customerCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "data-cy": "FormCustomerLocation-customerName",
                          label: "Customer Name",
                        },
                        model: {
                          value: _vm.localCustomerLocation.customerName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.localCustomerLocation,
                              "customerName",
                              $$v
                            )
                          },
                          expression: "localCustomerLocation.customerName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm.localCustomerLocation.id
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "GmapMap",
                            {
                              staticStyle: { width: "100%", height: "300px" },
                              attrs: { center: _vm.center, zoom: _vm.zoom },
                              on: { click: _vm.mapClicked },
                            },
                            [
                              _c("GmapMarker", {
                                key: 1,
                                attrs: {
                                  position: {
                                    lat: _vm.localCustomerLocation.lat,
                                    lng: _vm.localCustomerLocation.lng,
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                color: "primary",
                                loading: _vm.isBusy,
                                block: _vm.$vuetify.breakpoint.mobile,
                              },
                              on: { click: _vm.getCurrentPosition },
                            },
                            [_vm._v(" Get my location ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              filled: "",
                              items: _vm.positions,
                              "data-cy": "FormCustomerLocation-manager",
                              label: _vm.$$t("manager"),
                            },
                            model: {
                              value: _vm.localCustomerLocation.manager,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localCustomerLocation,
                                  "manager",
                                  $$v
                                )
                              },
                              expression: "localCustomerLocation.manager",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            attrs: {
                              filled: "",
                              items: _vm.restaurantTypes,
                              "data-cy": "FormCustomerLocation-restaurantType",
                              label: _vm.$$t("restaurantType"),
                            },
                            model: {
                              value: _vm.localCustomerLocation.restaurantType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localCustomerLocation,
                                  "restaurantType",
                                  $$v
                                )
                              },
                              expression:
                                "localCustomerLocation.restaurantType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              filled: "",
                              "data-cy":
                                "FormCustomerLocation-receivingContact",
                              label: _vm.$$t("receivingContact"),
                            },
                            model: {
                              value: _vm.localCustomerLocation.receivingContact,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localCustomerLocation,
                                  "receivingContact",
                                  $$v
                                )
                              },
                              expression:
                                "localCustomerLocation.receivingContact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("CustomerLocationTimes", {
                    attrs: {
                      "customer-location": _vm.CustomerLocation,
                      "time-type": "Delivery",
                    },
                  }),
                  _c("div", { staticClass: "mt-10 mb-10" }),
                  _c("CustomerLocationTimes", {
                    attrs: {
                      "customer-location": _vm.CustomerLocation,
                      "time-type": "Trading",
                    },
                  }),
                  _c(
                    "v-row",
                    { staticClass: "mt-10" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              filled: "",
                              rows: "3",
                              "data-cy": "FormCustomerLocation-notes",
                              label: _vm.$$t("notes"),
                            },
                            model: {
                              value: _vm.localCustomerLocation.notes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localCustomerLocation,
                                  "notes",
                                  $$v
                                )
                              },
                              expression: "localCustomerLocation.notes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end mb-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4 btn-cancel",
                          attrs: {
                            color: "default",
                            outlined: "",
                            "data-cy": "FormCustomerLocation-button-cancel",
                          },
                          on: { click: _vm.cancel },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.buttons.cancel")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            color: "primary",
                            type: "submit",
                            loading: _vm.isSaving,
                            "data-cy": "FormCustomerLocation-button-save",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("common.buttons.save")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.isSaving, color: "success" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }