var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-col",
        {
          key: item.name,
          staticClass: "col-sm-padding",
          attrs: { cols: "12", md: "6", lg: "6", xl: "6" },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "router-link",
                { attrs: { to: { name: item.to } } },
                [
                  _c("v-img", {
                    attrs: { src: item.imageUrl, height: "200", width: "100%" },
                  }),
                ],
                1
              ),
              _c(
                "v-card-title",
                { attrs: { "primary-title": "" } },
                [
                  _vm._v(" " + _vm._s(item.text) + " "),
                  _vm.isInRole("Administrators") &&
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c("ImageUpload", {
                        on: {
                          input: function ($event) {
                            return _vm.updateImage(item, $event)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }