var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading } },
        [
          _c(
            "v-card-text",
            [
              _c("FormCustomerLocation", {
                attrs: {
                  CustomerLocation: _vm.editCustomerLocation,
                  isSaving: _vm.isSaving,
                },
                on: {
                  create: _vm.create,
                  save: _vm.save,
                  cancel: function ($event) {
                    return _vm.$router.push({ name: _vm.from.name })
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }