var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.dailyInspections,
      search: _vm.search,
      loading: _vm.isLoading,
      "sort-by": "created",
      "sort-desc": "",
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              { class: { "p-16": _vm.$vuetify.breakpoint.mobile } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                  [
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "500px" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        loading: _vm.isSaving,
                                        color: "success",
                                        "data-cy":
                                          "VehicleInspection-button-new",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                                    ]),
                                    _vm._v(" Start a Daily Inspection "),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.dialog,
                          callback: function ($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [_vm._v(" Start Inspection ")]),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-form",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("v-select", {
                                      staticClass: "mb-5",
                                      attrs: {
                                        items: _vm.drivers,
                                        label: "Driver",
                                        "item-text": "name",
                                        "item-value": "id",
                                      },
                                      model: {
                                        value: _vm.dailyInspection.driverId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dailyInspection,
                                            "driverId",
                                            $$v
                                          )
                                        },
                                        expression: "dailyInspection.driverId",
                                      },
                                    }),
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.vehicles,
                                        "item-text": (v) =>
                                          `${v.make} ${v.model} ${v.licensePlate}`,
                                        "item-value": "id",
                                        label: "Vehicle",
                                      },
                                      model: {
                                        value: _vm.dailyInspection.vehicleId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dailyInspection,
                                            "vehicleId",
                                            $$v
                                          )
                                        },
                                        expression: "dailyInspection.vehicleId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialog = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: { click: _vm.startInspection },
                                  },
                                  [_vm._v(" Start ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": _vm.mdiMagnify,
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.created",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDateTime(item.created)) + " ")]
        },
      },
      {
        key: "item.openingVehicleInspectionId",
        fn: function ({ item }) {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  to: {
                    name: "fleet-EditVehicleInspection",
                    query: { id: item.openingVehicleInspectionId },
                  },
                },
              },
              [_vm._v(" View ")]
            ),
          ]
        },
      },
      {
        key: "item.closingVehicleInspectionId",
        fn: function ({ item }) {
          return [
            item.closingVehicleInspectionId
              ? _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      to: {
                        name: "fleet-EditVehicleInspection",
                        query: { id: item.closingVehicleInspectionId },
                      },
                    },
                  },
                  [_vm._v(" View ")]
                )
              : _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.startClosingInspection(item)
                      },
                    },
                  },
                  [_vm._v(" Start Closing Inspection ")]
                ),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c("TableBasicButtons", {
              attrs: {
                editLink: {
                  name: "fleet-EditVehicleInspection",
                  query: { id: item.id },
                },
              },
              on: {
                delete: function ($event) {
                  return _vm.remove(item)
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }