var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.suppliers,
      loading: _vm.isLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-toolbar",
              { attrs: { flat: "" } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "success",
                      "data-cy": "Supplier-button-new",
                      to: {
                        name: "shop-EditSupplier",
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("common.buttons.new")) + " ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c(
              "v-icon",
              {
                staticClass: "mr-2",
                attrs: {
                  small: "",
                  "data-cy": `button-Supplier-edit-${item.id}`,
                  to: {
                    name: "shop-EditSupplier",
                    query: { id: item.id },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.mdiPencil) + " ")]
            ),
            _c(
              "v-icon",
              {
                attrs: {
                  small: "",
                  color: "danger",
                  "data-cy": `button-Supplier-remove-${item.id}`,
                },
                on: {
                  click: function ($event) {
                    return _vm.remove(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.mdiDelete) + " ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }