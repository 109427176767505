var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.vehicleInspections,
      search: _vm.search,
      loading: _vm.isLoading,
      "sort-by": "created",
      "sort-desc": "",
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "v-row",
              { class: { "p-16": _vm.$vuetify.breakpoint.mobile } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          "data-cy": "VehicleInspection-button-new",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "fleet-EditVehicleInspection",
                            })
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(" " + _vm._s(_vm.mdiPlus) + " "),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("common.buttons.new")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": _vm.mdiMagnify,
                        label: "Search",
                        "single-line": "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "item.created",
        fn: function ({ item }) {
          return [_vm._v(" " + _vm._s(_vm.formatDateTime(item.created)) + " ")]
        },
      },
      {
        key: "item.vehicle",
        fn: function ({ item }) {
          return [
            item.vehicle
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        `${item.vehicle.make} ${item.vehicle.model} - ${item.vehicle.licensePlate}`
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c("TableBasicButtons", {
              attrs: {
                editLink: {
                  name: "fleet-EditVehicleInspection",
                  query: { id: item.id },
                },
              },
              on: {
                delete: function ($event) {
                  return _vm.remove(item)
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }