var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { class: { ios: _vm.isIos } },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "fill-height", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "fill-height d-flex flex-column align-stretch",
                      attrs: { cols: "12", md: "4" },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }