var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "60vw", transition: "dialog-transition" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm._v(" Upcoming "),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _vm.supplierBookings.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "text-medium", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            " Please note you have the following upcoming bookings: "
                          ),
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.supplierBookings,
                              function (booking, index) {
                                return _c(
                                  "v-list-item",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(booking.date) +
                                              ": " +
                                              _vm._s(booking.name) +
                                              " " +
                                              _vm._s(booking.vehicle) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.upcomingReminders.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "text-medium", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            " Please note you have the following upcoming reminders: "
                          ),
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.upcomingReminders,
                              function (booking, index) {
                                return _c(
                                  "v-list-item",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(booking.date) +
                                              ": " +
                                              _vm._s(booking.name) +
                                              " " +
                                              _vm._s(booking.vehicle) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Ok ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }