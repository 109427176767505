var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c("StockItemsLoading")
        : _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { attrs: { id: "container" } },
                  _vm._l(_vm.visibleItems, function (category, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "div",
                          { staticClass: "text-h5 font-weight-bold ml-3" },
                          [_vm._v(" " + _vm._s(category.name) + " ")]
                        ),
                        _c("CategoryCardList", {
                          attrs: { category: category, depth: _vm.depth },
                          on: { input: _vm.selectCategory },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }