var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column align-center justify-center",
              attrs: { cols: "12", "offset-lg": "3", lg: "6" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "w-100",
                  staticStyle: { border: "2px solid #0067b3" },
                  attrs: { flat: "" },
                },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v("Contact Us"),
                  ]),
                  _vm.error
                    ? _c("v-alert", { attrs: { value: true, type: "error" } }, [
                        _vm._v(
                          " There was an error processing your request, please try again later. "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name",
                              outlined: "",
                              rules: _vm.rules.required,
                              required: "",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Email",
                              type: "email",
                              outlined: "",
                              rules: _vm.rules.email,
                              required: "",
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                          _c("v-textarea", {
                            attrs: {
                              rows: "3",
                              label: "Message",
                              outlined: "",
                              rules: _vm.rules.required,
                              required: "",
                            },
                            model: {
                              value: _vm.form.message,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "message", $$v)
                              },
                              expression: "form.message",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                type: "submit",
                                color: "primary",
                                loading: _vm.isSaving,
                              },
                            },
                            [_vm._v(" Send message ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.success
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            value: true,
                            type: "success",
                            dismissible: "",
                          },
                        },
                        [
                          _vm._v(
                            " Thank you for you feedback, we will be in touch shortly. "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.success
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            to: { name: "shop-ShopHome" },
                          },
                        },
                        [_vm._v(" Back Home ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }