var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    transition: "expand-transition",
                    type: "article",
                  },
                },
                [
                  _c("FormSoftSaleStockItem", {
                    attrs: {
                      SoftSaleStockItem: _vm.editSoftSaleStockItem,
                      isSaving: _vm.isSaving,
                    },
                    on: {
                      save: _vm.save,
                      cancel: function ($event) {
                        return _vm.$router.push({
                          name: "shop-ListSoftSaleStockItem",
                          query: {
                            customerCode:
                              _vm.editSoftSaleStockItem.customerCode,
                          },
                        })
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }