var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormPickingListItem-itemCode",
                      label: _vm.$$t("itemCode"),
                    },
                    model: {
                      value: _vm.localPickingListItem.itemCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.localPickingListItem, "itemCode", $$v)
                      },
                      expression: "localPickingListItem.itemCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      filled: "",
                      "data-cy": "FormPickingListItem-quantity",
                      label: _vm.$$t("quantity"),
                    },
                    model: {
                      value: _vm.localPickingListItem.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.localPickingListItem, "quantity", $$v)
                      },
                      expression: "localPickingListItem.quantity",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormPickingListItem-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormPickingListItem-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }