var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      solo: "",
                      items: _vm.fleetSuppliers,
                      "item-text": "name",
                      "item-value": "id",
                      "data-cy": "FormFleetSupplierBooking-fleetSupplierId",
                      label: _vm.$$t("fleetSupplierId"),
                    },
                    model: {
                      value: _vm.localFleetSupplierBooking.fleetSupplierId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localFleetSupplierBooking,
                          "fleetSupplierId",
                          $$v
                        )
                      },
                      expression: "localFleetSupplierBooking.fleetSupplierId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      solo: "",
                      items: _vm.vehicles,
                      "item-text": (v) =>
                        `${v.make} ${v.model} (${v.licensePlate})`,
                      "item-value": "id",
                      "data-cy": "FormFleetSupplierBooking-vehicleId",
                      label: _vm.$$t("vehicleId"),
                    },
                    model: {
                      value: _vm.localFleetSupplierBooking.vehicleId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localFleetSupplierBooking,
                          "vehicleId",
                          $$v
                        )
                      },
                      expression: "localFleetSupplierBooking.vehicleId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("DatePicker", {
                    attrs: {
                      solo: "",
                      "data-cy": "FormFleetSupplierBooking-startDate",
                      label: _vm.$$t("startDate"),
                    },
                    model: {
                      value: _vm.localFleetSupplierBooking.startDate,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.localFleetSupplierBooking,
                          "startDate",
                          $$v
                        )
                      },
                      expression: "localFleetSupplierBooking.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("DatePicker", {
                    attrs: {
                      solo: "",
                      "data-cy": "FormFleetSupplierBooking-endDate",
                      label: _vm.$$t("endDate"),
                    },
                    model: {
                      value: _vm.localFleetSupplierBooking.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.localFleetSupplierBooking, "endDate", $$v)
                      },
                      expression: "localFleetSupplierBooking.endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-row"),
          _c(
            "div",
            { staticClass: "d-flex justify-end mb-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-4 btn-cancel",
                  attrs: {
                    color: "default",
                    outlined: "",
                    "data-cy": "FormFleetSupplierBooking-button-cancel",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    loading: _vm.isSaving,
                    "data-cy": "FormFleetSupplierBooking-button-save",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.buttons.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }