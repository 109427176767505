var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-file-input", {
    ref: "file",
    staticClass: "favourites-import",
    attrs: {
      label: "Import Favourites",
      rules: _vm.fileRules,
      loading: _vm.isSaving,
      "hide-details": "",
    },
    scopedSlots: _vm._u([
      {
        key: "prepend",
        fn: function () {
          return [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFileImport))])]
        },
        proxy: true,
      },
      {
        key: "append",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  text: "",
                  filled: "",
                  "hide-details": "",
                },
                on: { click: _vm.importFavourites },
              },
              [_vm._v(" Upload ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v
      },
      expression: "file",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }