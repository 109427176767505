var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-6", attrs: { id: "cart-list-categories", fluid: "" } },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            { staticClass: "d-flex" },
            _vm._l(_vm.categories, function (category, index) {
              return _c(
                "v-col",
                {
                  key: `category-${index}`,
                  staticClass: "d-flex align-stretch col-sm-padding",
                  attrs: {
                    cols: "12",
                    xs: "12",
                    sm: "6",
                    md: "4",
                    lg: "2",
                    xl: "2",
                    "data-cy": `category-${category.name?.toLowerCase()}`,
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "fill-height category-card",
                      attrs: { elevation: "1" },
                    },
                    [
                      _c(
                        "v-img",
                        {
                          staticClass: "d-flex align-stretch",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            "data-cy": `category-link-${index}`,
                            src: category.photoUrl,
                            height: "150px",
                            stretch: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectCategory(category)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "fill-height w-100 d-flex",
                              class: { "category-overlay": !_vm.hideOverlay },
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "mt-1 mb-0 pl-2 pr-2 white--text",
                                  staticStyle: {
                                    "align-self": "end",
                                    "line-height": "1.2em",
                                    padding: "12px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectCategory(category)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(category.name) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "v-row",
            { staticClass: "d-flex" },
            _vm._l(_vm.categories, function (category, index) {
              return _c(
                "v-col",
                {
                  key: `category-${index}`,
                  staticClass: "d-flex flex-row align-stretch pa-2",
                  attrs: {
                    cols: "6",
                    xs: "6",
                    sm: "4",
                    md: "4",
                    lg: "2",
                    xl: "2",
                    "data-cy": `category-${category.name?.toLowerCase()}`,
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "fill-height category-card w-100",
                      attrs: { elevation: "1" },
                    },
                    [
                      _c(
                        "v-img",
                        {
                          staticClass: "d-flex align-stretch",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            "data-cy": `category-link-${index}`,
                            src: category.photoUrl,
                            height: _vm.$vuetify.breakpoint.mobile
                              ? "120px"
                              : "150px",
                            stretch: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectCategory(category)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "fill-height w-100 d-flex",
                              class: { "category-overlay": !_vm.hideOverlay },
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "mt-1 mb-0 pl-2 pr-2 white--text",
                                  staticStyle: {
                                    "align-self": "end",
                                    "line-height": "1.2em",
                                    padding: "12px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectCategory(category)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(category.name) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }