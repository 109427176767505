var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              color: "primary",
                              title: "User Account",
                              elevation: "0",
                              height: "48",
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("@/assets/icon-account-white.svg"),
                          },
                        }),
                      ]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            4077134199
          ),
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { attrs: { to: { name: "app-Account" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("common.account"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.logout } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("common.logoff"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }