var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: _vm.accept },
        on: {
          change: function ($event) {
            return _vm.fileSelected($event.target.files[0])
          },
        },
      }),
      _c(
        "v-card",
        {
          staticClass: "dotted-border",
          class: {
            "grey lighten-2": _vm.dragover,
            "grey--text": !_vm.dragover,
          },
          attrs: { flat: "", "data-cy": "File-Upload" },
          on: {
            drop: function ($event) {
              $event.preventDefault()
              return _vm.onDrop($event)
            },
            dragover: function ($event) {
              $event.preventDefault()
              _vm.dragover = true
            },
            dragenter: function ($event) {
              $event.preventDefault()
              _vm.dragover = true
            },
            dragleave: function ($event) {
              $event.preventDefault()
              _vm.dragover = false
            },
          },
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                {
                  staticClass: "d-flex flex-column",
                  attrs: { dense: "", align: "center", justify: "center" },
                },
                [
                  _vm.isLoading
                    ? _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" },
                      })
                    : _c(
                        "v-icon",
                        {
                          class: [_vm.dragover ? "mt-2, mb-6" : "mt-5"],
                          attrs: { color: "black", size: "60" },
                        },
                        [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")]
                      ),
                  _c("p", { staticClass: "black--text" }, [
                    _vm._v(" " + _vm._s(_vm.label) + " "),
                  ]),
                  _c("p", [_vm._v(" - OR - ")]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.fileInput.click()
                        },
                      },
                    },
                    [_vm._v(" BROWSE FILES ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }