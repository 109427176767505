import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('salesOrder', 'salesOrders');

const customActions = {
  async LOAD_salesOrder({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`salesOrders(${id})`)
      .query({ $expand: 'Lines,Prints' });
    commit('SET_salesOrder', data);
    return data;
  },
  async LOAD_salesOrders({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('salesOrders')
      .query({ $expand: 'Lines' });
    commit('SET_salesOrders', data);
    return data;
  },
};

export default { ...actions, ...customActions };
