var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "", "align-center": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", "offset-lg": "3" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Describe the product",
                          "data-cy": "ProductRequest-description",
                          outlined: "",
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                      _c("FileUploader", { on: { input: _vm.updateImage } }),
                      _vm.photoUrl
                        ? _c("v-img", {
                            attrs: {
                              src: _vm.photoUrl,
                              contain: "",
                              "max-height": "200",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-cy": "ProductRequest-button-cancel",
                                    color: "success",
                                    block: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "data-cy": "ProductRequest-button-ok",
                                    color: "primary",
                                    block: "",
                                  },
                                  on: { click: _vm.requestProduct },
                                },
                                [_vm._v(" Send request ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }