<template>
  <v-row>
    <v-col cols="6">
      <table>
        <tbody>
          <tr>
            <td>Order Date:</td>
            <td>{{ formatDate(salesOrder.orderDate) }}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td><b>{{ salesOrder.status }}</b></td>
          </tr>
          <tr>
            <td>To:</td>
            <td>{{ salesOrder.customerName }}</td>
          </tr>
          <tr>
            <td />
            <td>{{ salesOrder.deliveryAddress1 }}</td>
          </tr>
          <tr>
            <td />
            <td>{{ salesOrder.deliveryAddress2 }}</td>
          </tr>
          <tr>
            <td />
            <td>{{ salesOrder.deliveryAddress3 }}</td>
          </tr>
          <tr>
            <td />
            <td>{{ salesOrder.deliveryAddress4 }}</td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="6">
      <table>
        <tbody>
          <tr>
            <td>Delivery Date:</td>
            <td><b>{{ formatDate(salesOrder.orderDate) }}</b></td>
          </tr>
          <tr>
            <td>Version:</td>
            <td><b>{{ salesOrder.printCount }}</b></td>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SalesOrderHeader',
  props: {
    salesOrder: {
      type: Object,
      required: true,
    },
  },
};
</script>
