<template>
  <v-container fluid>
    <v-row v-if="salesOrder">
      <v-col cols="6">
        <table>
          <tbody>
            <tr>
              <td>Order Date:</td>
              <td>{{ formatDate(salesOrder.orderDate) }}</td>
            </tr>
            <tr>
              <td>To:</td>
              <td>{{ salesOrder.customerName }}</td>
            </tr>
            <tr>
              <td />
              <td>{{ salesOrder.deliveryAddress1 }}</td>
            </tr>
            <tr>
              <td />
              <td>{{ salesOrder.deliveryAddress2 }}</td>
            </tr>
            <tr>
              <td />
              <td>{{ salesOrder.deliveryAddress3 }}</td>
            </tr>
            <tr>
              <td />
              <td>{{ salesOrder.deliveryAddress4 }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="6">
        <table>
          <tbody>
            <tr>
              <td>Delivery Date:</td>
              <td>{{ formatDate(salesOrder.orderDate) }}</td>
            </tr>
            <tr>
              <td>Route:</td>
              <td />
            </tr>
            <tr>
              <td>Version:</td>
              <td>{{ salesOrder.printCount }}</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row v-if="salesOrder">
      <v-col cols="4" />
      <v-col cols="4" class="text-center">Order Number: <b>{{ salesOrder.id }}</b></v-col>
      <v-col cols="4" class="text-right">User: {{ username }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <table class="table-picking">
          <thead>
            <tr>
              <th>
                Code
              </th>
              <th>
                Description
              </th>
              <th>
                Qty
              </th>
              <th>
                Unit
              </th>
              <th>
                Bin
              </th>
              <th>
                Printer
              </th>
              <th>
                Picked
              </th>
              <th />
            </tr>
          </thead>
          <tbody v-if="lines.length > 0">
            <tr v-for="(line, index) in lines" :key="index">
              <td>{{ line.itemCode }}</td>
              <td>{{ line.itemDescription }}</td>
              <td>{{ line.originalQuantity }}</td>
              <td>{{ line.unitOfMeasure }}</td>
              <td>{{ line.bin }}</td>
              <td>{{ line.printer }}</td>
              <td><input type="number" v-model.number="line.quantity" @focus="onPickedFocus" /></td>
              <td>
                <v-btn color="error" @click="removeLine(line)">
                  Delete
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="6" v-if="salesOrder">
        <PrintPickingSlip
          :sales-order="salesOrder" :lines="lines" @updatePrintCount="updatePrintCount"
        />
      </v-col>
      <v-col cols="6" class="flex flex-column align-end">
        <v-btn block color="accent" class="mb-5" @click="save" :loading="isSaving">Save</v-btn>
        <v-btn block color="success" @click="complete" :loading="isSaving">Complete</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import PrintPickingSlip from '../components/PrintPickingSlip.vue';

export default {
  name: 'PickSalesOrder',
  components: {
    PrintPickingSlip,
  },
  module: 'Shop',
  mixins: [loading],
  data() {
    return {
      salesOrderId: this.$route.query.salesOrderId,
      orderId: this.$route.query.orderId,
      order: undefined,
      lines: [],
    };
  },
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('salesOrders', ['salesOrder']),
  },
  mounted() {
    this.SET_title('Picking Slip');
    this.refresh();
  },
  methods: {
    ...mapActions('salesOrders', ['LOAD_salesOrder']),
    ...mapActions('salesOrderLines', ['LOAD_salesOrderLinesBySalesOrder']),
    ...mapActions('orders', ['LOAD_order']),
    ...mapActions('customers', ['LOAD_customerById']),
    ...mapMutations('app', ['SET_title', 'SET_expandedSearch']),
    onPickedFocus(event) {
      event.target.select();
    },
    async refresh() {
      try {
        await Promise.all([
          (async () => {
            await this.LOAD_salesOrder(this.salesOrderId);
          })(),
          (async () => {
            this.order = await this.LOAD_order(this.orderId);
          })(),
          (async () => {
            this.lines = await this.LOAD_salesOrderLinesBySalesOrder(this.salesOrderId);
          })(),
        ]);
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error loading picking slip');
      } finally {
        this.loading = false;
      }
    },
    async removeLine(line) {
      const res = await this.$confirm('Are you sure you want to delete this line?');
      if (!res) return;
      try {
        await this.$http.delete(`/odata/salesOrderLines(${line.id})`);
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error deleting line');
      }
      await this.refresh();
    },
    async complete() {
      try {
        this.isSaving = true;
        await this.$http.post(`/odata/salesOrders(${this.salesOrderId})/pick`, {
          model: {
            salesOrderId: parseInt(this.salesOrderId, 10),
            lines: this.lines.map((l) => ({
              id: l.id,
              quantity: l.quantity,
            })),
          },
        });
        this.$root.$emit('toast:success', 'Picking slip completed');
        this.$router.push({ name: 'shop-AdminOrders' });
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error saving picking slip');
      } finally {
        this.isSaving = false;
      }
    },
    async updatePrintCount() {
      await this.$http.patch(`/odata/salesOrders(${this.salesOrder.id})`, {
        printCount: this.salesOrder.printCount + 1,
      });
      await this.refresh();
    },
    async save() {
      try {
        this.isSaving = true;
        this.lines.forEach(async (l) => {
          await this.$http.patch(`/odata/salesOrderLines(${l.id})`, {
            quantity: l.quantity,
          });
        });
        this.$root.$emit('toast:notify', 'Picking slip saved');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error saving picking slip');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
<style>
.table-picking {
  width: 100%;
}
.table-picking tbody td,
.table-picking thead th {
  text-align: left;
  padding: 5px;
}
.table-picking thead th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.table-picking input {
  width: 50px;
  text-align: right;
  padding: 5px;
  border: 1px solid #ccc;
}
</style>
